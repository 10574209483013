import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { Alert, IconButton, InputAdornment, Link, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { PATH_AUTH } from '../../../routes/paths';
import { checkErrorCode } from '../../../utils/helpers';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Adresse e-mail non valide').required('Ce champ est requis.'),
    password: Yup.string().required('Ce champ est requis.')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const resplogin = await login(values.email, values.password);
        if (resplogin.status && resplogin.status === 401) {
          setErrors({ afterSubmit: resplogin.message });
        } else {
          enqueueSnackbar('Vous êtes connecté', { variant: 'success' });
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        }
      } catch (error) {
        if (checkErrorCode(401, error)) {
          setErrors({ afterSubmit: 'E-mail ou mot de passe incorrect' });
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="ContentBlocFormAuthentification">
          <Stack spacing={3}>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
            <span className="TitreChamp">Adresse e-mail</span>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label=""
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              disabled={isSubmitting}
              className="ChampLogin"
            />
            <span className="TitreChamp">Mot de passe</span>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label=""
              {...getFieldProps('password')}
              className="ChampLogin"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              disabled={isSubmitting}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ my: 2 }}
            className="BoutonSeConnecter"
          >
            Se connecter
          </LoadingButton>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
          <Link
            href={`${process.env.REACT_APP_FO_URL}/Creer-mon-compte`}
            className="LienMotdePasse"
          >
            S'inscrire
          </Link>
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword} className="LienMotdePasse">
            Mot de passe oublié?
          </Link>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
