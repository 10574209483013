import gql from 'graphql-tag';

export const ARTICLE_LIST_FRAGMENT = gql`
  fragment ARTICLE_LIST_FRAGMENT on ${process.env.REACT_APP_HASURA_SCHEMA}db_article {
    article_id
    title
    reference
    status
    date_create
    db_agency {
      agency_id
      name
    }
    info_agency
    db_training_articles {
      db_city {
        name
        db_region {
          name
        }
      }
    }
    db_article_life_duration {
      article_life_duration_id
      activation_date
      desactivation_date
    }
    db_article_option_paids {
      article_option_paid_id
      db_option {
        option_id
        duration
        db_option_type {
          value
        }
      }
      end_option_date
    }
  }
`;
