import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contactList: []
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE AGENCIES
    getContactListSuccess(state, action) {
      state.isLoading = false;
      state.contactList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ---------------------------------------------------------------------

export const getContact = createAsyncThunk('contact/getContact', async (id, { dispatch }) => {
  const response = await axios.get(`/api/contacts/${id}`);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const addContact = createAsyncThunk('contact/addContact', async (contactData, { dispatch }) => {
  const response = await axios.post('/api/contacts', contactData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateContact = createAsyncThunk('contact/updateContact', async ({ id, contactData }, { dispatch }) => {
  const response = await axios.put(`/api/contacts/${id}`, contactData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteContact = createAsyncThunk('contact/deleteContact', async (id, { dispatch }) => {
  const response = await axios.delete(`/api/contacts/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

export const getContacts = createAsyncThunk('contact/getContacts', async (agencyId, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const url = (agencyId && `/api/agencies/${agencyId}/contacts`) || '/api/contacts';
  const response = await axios.get(url);
  dispatch(slice.actions.getContactListSuccess(response.data['hydra:member']));
  return response === undefined ? null : response.data;
});
