import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  countryList: [],
  country: {}
};

const slice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE COUNTRIES
    getCountryListSuccess(state, action) {
      state.isLoading = false;
      state.countryList = action.payload;
    },

    getCountry(state, action) {
      state.isLoading = false;
      state.country = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ---------------------------------------------------------------------

export const getCountry = createAsyncThunk('country/getCountry', async (id, { dispatch }) => {
  const response = await axios.get(`/api/countries/${id}`);
  dispatch(slice.actions.getCountry(response.data));
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const addCountry = createAsyncThunk('country/addCountry', async (countryData, { dispatch }) => {
  const response = await axios.post('/api/countries', countryData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateCountry = createAsyncThunk('country/updateCountry', async ({ id, countryData }, { dispatch }) => {
  const response = await axios.put(`/api/countries/${id}`, countryData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteCountry = createAsyncThunk('country/deleteCountry', async (id, { dispatch }) => {
  const response = await axios.delete(`/api/countries/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

export const getCountries = createAsyncThunk(
  'country/getCountries',
  async ({ page, orderBy, order, itemsPerPage, name }, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(
      `/api/countries?page=${page}&itemsPerPage=${itemsPerPage}&pagination=true&order%5B${orderBy}%5D=${order}${
        name && name.length ? `&name=${name}` : ''
      }`
    );
    dispatch(slice.actions.getCountryListSuccess(response.data['hydra:member']));
    return response === undefined ? null : response.data;
  }
);
