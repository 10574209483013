import gql from 'graphql-tag';

export const LOGIN = gql`
  query LOGIN($email: String!, $password: String!) {
    Login(email: $email, password: $password) {
      user_id
      firstname
      email
      lastname
      phone
      db_agency {
        agency_id
        name
        db_public_info_agency {
          dbMediaByMainLogoId {
            link_full
          }
        }
        db_payements {
          db_subscription_account {
            subscrition_account_id
            start_subscription_date
            end_subscription_date
            annonces_left
            db_subscription {
              subcription_id
              name
              description
              periode
              price
              number_seformer
            }
          }
        }
      }
      db_role {
        name
        role_id
        visible_name
      }
      token
    }
  }
`;

export const PWD_REQUEST = gql`
  mutation PWD_REQUEST($email: String!) {
    sendEmailResetPwd(email: $email, isCreation: false) {
      errors
      mailSent
    }
  }
`;

export const RESET_PWD = gql`
  mutation RESET_PWD($token: String!, $password: String!, $username: String!) {
    SetUserPassword(token: $token, password: $password, username: $username) {
      status
      message
    }
  }
`;
