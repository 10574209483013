import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { axiosGraphql } from '../../utils/axios';
import { ADD_AGENCY } from '../../graphql/mutations/agency';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  option: {},
  agencyList: []
};

const slice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE AGENCIES
    getAgencyListSuccess(state, action) {
      state.isLoading = false;
      state.agencyList = action.payload;
    },

    setAgencyOption(state, action) {
      state.option = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions



export const getAgency = createAsyncThunk('agency/getAgency', async (id, { dispatch }) => {
  const response = await axios.get(`/api/agencies/${id}`);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const addAgency = createAsyncThunk('agency/addAgency', async (agencyData, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({ query: ADD_AGENCY, variables: agencyData });
  console.log('response => ', response);
  dispatch(slice.actions.endLoading());
});

export const updateAgency = createAsyncThunk(
  'agency/updateAgency',
  async ({ id, agencyData }, { dispatch }) => {
    const response = await axios.put(`/api/agencies/${id}`, agencyData);
    dispatch(slice.actions.endLoading());
    return response.data === undefined ? null : response.data;
  }
);

export const deleteAgency = createAsyncThunk('agency/deleteAgency', async (id, { dispatch }) => {
  const response = await axios.delete(`/api/agencies/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

export const getAgencies = createAsyncThunk('agency/getAgencies', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.get('/api/agencies');
  dispatch(slice.actions.getAgencyListSuccess(response.data['hydra:member']));
  return response === undefined ? null : response.data;
});