import gql from 'graphql-tag';

export const ADD_AGENCY = gql`
  mutation ADD_AGENCY($agency: app_agency_insert_input!) {
    insert_app_agency_one(object: $agency) {
      id
      name
    }
  }
`;

export const UPDATE_AGENCY = gql`
  mutation UPDATE_AGENCY($id: Int!, $agency: app_agency_set_input!) {
    update_app_agency(where: { id: { _eq: $id } }, _set: $agency) {
      returning {
        id
        name
      }
    }
  }
`;

export const DELETE_AGENCY = gql`
  mutation DELETE_AGENCY($id: Int!) {
    delete_app_agency(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
