import gql from 'graphql-tag';

export const ADD_USER = gql`
  mutation AddUser(
    $email: String!
    $firstname: String!
    $lastname: String!
    $phone: String
    $is_active: Boolean!
    $role_id: Int!
    $agency_id: Int
    $username: String!
  ) {
    AddUser(
      email: $email
      password: ""
      firstname: $firstname
      lastname: $lastname
      phone: $phone
      is_active: $is_active
      role_id: $role_id
      agency_id: $agency_id
      username: $username
    ) {
      user_id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $agencyId: Int
    $email: String!
    $firstname: String!
    $groupId: Int!
    $lastname: String!
    $password: String!
    $phone: String!
    $id: Int!
  ) {
    UpdateUser(
      agencyId: $agencyId
      email: $email
      firstname: $firstname
      groupId: $groupId
      lastname: $lastname
      password: $password
      phone: $phone
      id: $id
    ) {
      email
      firstname
      id
      lastname
      phone
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($id: Int!) {
    delete_seformer_db_user(where: { user_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
