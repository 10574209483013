import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosGraphql } from '../../utils/axios';
import { ADD_USER, UPDATE_USER, DELETE_USER } from '../../graphql/mutations/user';
import { USER, USERS, USERLIST, USERBYMAIL, MODERATORLIST } from '../../graphql/queries/user';
import { UPDATEACCOUNT } from '../../graphql/mutations/accountsetting';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  users: [],
  userList: [],
  user: {}
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER
    getUser(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // GET USER BY EMAIL
    getUserByEmail(state, action) {
        state.isLoading = false;
        state.user = action.payload;
    },

    // GET USERS
    getUsers(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    // STORE USER INFO
    storeInfoUser(state, action) {
      state.user = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const getUserList = createAsyncThunk('user/getUserList', async (agencyID, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: USERLIST, variables: {agency_id: agencyID}});
  dispatch(slice.actions.getUserListSuccess(res.data?.data?.seformer_db_user));
  dispatch(slice.actions.endLoading());
  return res.data?.data?.seformer_db_user === undefined ? null : res.data.data.seformer_db_user;
});

export const getUser = createAsyncThunk('user/getUser', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: USER, variables: { id } });
  dispatch(slice.actions.getUser(res.data?.data?.seformer_db_user_by_pk));
  return res.data?.data?.seformer_db_user_by_pk === undefined ? null : res.data?.data?.seformer_db_user_by_pk;
});

export const getUserByEmail = createAsyncThunk('user/getUserByEmail', async (email, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    const res = await axiosGraphql({ query: USERBYMAIL, variables: { email } });
    dispatch(slice.actions.getUserByEmail(res.data?.data?.seformer_db_user));
    return res.data?.data?.seformer_db_user === undefined ? null : res.data?.data?.seformer_db_user;
});

export const getUsers = createAsyncThunk('user/getUsers', async ({ dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: USERS });
  dispatch(slice.actions.getUsers(res.data?.data?.app_user));
  return res.data?.data?.app_user === undefined ? null : res.data?.data?.app_user;
});

export const updateUser = createAsyncThunk('user/updateUser', async ({ id, user }, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: UPDATE_USER, variables: { ...user, id } });
  dispatch(slice.actions.endLoading());
  return res.data === undefined ? null : res.data;
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: DELETE_USER, variables: { id } });
  dispatch(slice.actions.endLoading());
  return res.data === undefined ? null : res.data;
});

export const updateAccount = createAsyncThunk('user/updateAccount', async (userInfo, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: UPDATEACCOUNT, variables: userInfo });
  dispatch(slice.actions.endLoading());
  return res.data === undefined ? null : res.data;
});

export const addUser = createAsyncThunk('user/AddUser', async (userdata, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: ADD_USER, variables: userdata });
  dispatch(slice.actions.endLoading());
  return res.data === undefined ? null : res.data;
});

export const storeUserInfo = createAsyncThunk('user/storeUserInfo', async (userInfos, { dispatch }) => {
  dispatch(slice.actions.storeInfoUser( userInfos ));
});

export const getModeratorList = createAsyncThunk('user/getModeratorList', async (roleID, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: MODERATORLIST, variables: {role_id: roleID}});
  dispatch(slice.actions.getUserListSuccess(res.data?.data?.seformer_db_user));
  dispatch(slice.actions.endLoading());
  return res.data?.data?.seformer_db_user === undefined ? null : res.data.data.seformer_db_user;
});