import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  typesList: []
};

const slice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE AGENCIES
    getTypesListSuccess(state, action) {
      state.isLoading = false;
      state.typesList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ---------------------------------------------------------------------

export const getTypes = createAsyncThunk('types/getTypes', async (id, { dispatch }) => {
  const response = await axios.get(`/api/types/${id}`);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const addTypes = createAsyncThunk('types/addTypes', async (typesData, { dispatch }) => {
  const response = await axios.post('/api/types', typesData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateTypes = createAsyncThunk('types/updateTypes', async ({ id, typesData }, { dispatch }) => {
  const response = await axios.put(`/api/types/${id}`, typesData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteTypes = createAsyncThunk('types/deleteTypes', async (id, { dispatch }) => {
  const response = await axios.delete(`/api/types/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

export const getAllTypes = createAsyncThunk('types/getAllTypes', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const url = '/api/types';
  const response = await axios.get(url);
  dispatch(slice.actions.getTypesListSuccess(response.data['hydra:member']));
  return response === undefined ? null : response.data;
});
