import gql from 'graphql-tag';

export const UPDATEACCOUNT = gql`
  mutation UpdateProfile($user_id: Int!, $email: String, $phone: String, $firstname: String, $lastname: String, $fonction_id: Int, $old_password: String!, $password:String!) {
    update_seformer_db_user_by_pk(pk_columns: {user_id: $user_id}, _set: {email: $email, phone: $phone, firstname: $firstname, lastname: $lastname, fonction_id: $fonction_id}) {
      user_id
    }
    UpdatePassword(old_password: $old_password, password: $password, user_id: $user_id) {
      user_id
    }
  }
`;