import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import articleReducer from './slices/article';
import entrepriseReducer from './slices/entrepriseGraphql';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import agencyReducer from './slices/agency';
import contactReducer from './slices/contact';
import sectorReducer from './slices/sector';
import typesReducer from './slices/types';
import publicadmisReducer from './slices/publicadmis';
import mobilizabledeviceReducer from './slices/mobilizabledevice';
import countryReducer from './slices/country';
import regionReducer from './slices/region';
import cityReducer from './slices/city';
import levelReducer from './slices/level';
import degreeReducer from './slices/degree';
// grapqh slice
// import agencyGraphqlReducer from './slices/agencyGraphql';
import userGraphqlReducer from './slices/userGraphql';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  // graphQl reducer
  // agencyGraphl: agencyGraphqlReducer,
  userGraphql: userGraphqlReducer,
  //
  article: articleReducer,
  entreprise: entrepriseReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  agency: agencyReducer,
  contact: contactReducer,
  sector: sectorReducer,
  types: typesReducer,
  publicadmis: publicadmisReducer,
  mobilizabledevice: mobilizabledeviceReducer,
  country: countryReducer,
  region: regionReducer,
  city: cityReducer,
  level: levelReducer,
  degree: degreeReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
