import { Assessment, LocationCity, Public, Room, School } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBullhorn,
  faFileAlt,
  faEdit,
  faChartLine,
  faNewspaper,
  faCogs
} from '@fortawesome/free-solid-svg-icons';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  agency: getIcon('ic_agency'),
  country: <Public />,
  user: <FontAwesomeIcon icon={faEdit} />,
  region: <Room />,
  city: <LocationCity />,
  level: <Assessment />,
  degree: <School />,
  contact: getIcon('ic_contact'),
  sector: getIcon('ic_sector'),
  types: getIcon('ic_types'),
  publicadmis: getIcon('ic_publicadmis'),
  mobilizabledevice: getIcon('ic_mobilizabledevice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: <FontAwesomeIcon icon={faBullhorn} />,
  analytics: <FontAwesomeIcon icon={faFileAlt} />,
  dashboard: <FontAwesomeIcon icon={faHome} />,
  chart: <FontAwesomeIcon icon={faChartLine} />,
  subscription: <FontAwesomeIcon icon={faNewspaper} />,
  accountSettings: <FontAwesomeIcon icon={faCogs} />,

  kanban: getIcon('ic_kanban')
};
const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Accueil',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'Mes annonces',
        path: PATH_DASHBOARD.articles.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'Mes annonces actives', path: '/articles/active' },
          { title: 'Mes annonces en attente de validation', path: '/articles/pending' },
          { title: 'Mes annonces désactivées', path: '/articles/disable' },
        ]
      },
      // { title: 'CVthèque', path: '', icon: ICONS.analytics },
      {
        title: 'Fiche entreprise',
        path: PATH_DASHBOARD.ficheEntreprise.root,
        icon: ICONS.user,
        children: [
          { title: 'Ma fiche entreprise', path: PATH_DASHBOARD.ficheEntreprise.maFicheEntreprise },
        ]
      },

      // { title: 'Statistiques', path: '', icon: ICONS.chart },

      // MANAGEMENT : Subscription
      {
        title: 'Abonnement',
        path: PATH_DASHBOARD.subscription.root,
        icon: ICONS.subscription,
        children: [
          { title: 'Mon abonnement', path: PATH_DASHBOARD.subscription.current },
          { title: 'Changer mon abonnement', path: PATH_DASHBOARD.subscription.change },
          // { title: 'Mes factures', path: PATH_DASHBOARD.subscription.invoices }
        ]
      },

      {
        title: 'Gestion des utilisateurs',
        path: PATH_DASHBOARD.accountSettings.root,
        icon: ICONS.accountSettings,
        children: [
          { title: 'Mon compte', path: PATH_DASHBOARD.accountSettings.changePassword },
          { title: 'Liste des utilisateurs', path: PATH_DASHBOARD.accountSettings.addNewMember }
        ]
      },

      // {
      //   title: 'Niveaux de formation',
      //   path: PATH_DASHBOARD.level.list,
      //   icon: ICONS.level
      // },
      // {
      //   title: 'Diplômes',
      //   path: PATH_DASHBOARD.degree.list,
      //   icon: ICONS.degree
      // },
      // MANAGEMENT : CONTACT
      // {
      //   title: 'Contact',
      //   path: PATH_DASHBOARD.contact.list,
      //   icon: ICONS.contact
      // },
      // MANAGEMENT : SECTOR
      // {
      //   title: 'Secteur',
      //   path: PATH_DASHBOARD.sector.list,
      //   icon: ICONS.sector
      // },
      // MANAGEMENT : TRAINNING TYPES
      // {
      //   title: 'Types',
      //   path: PATH_DASHBOARD.types.list,
      //   icon: ICONS.types
      // },
      // MANAGEMENT : Public Admis
      // {
      //   title: 'Administration publique',
      //   path: PATH_DASHBOARD.publicadmis.list,
      //   icon: ICONS.publicadmis
      // },
      // MANAGEMENT : MOBILIZABLE DEVICES
      // {
      //   title: 'Dispositifs mobilisables',
      //   path: PATH_DASHBOARD.mobilizabledevice.list,
      //   icon: ICONS.mobilizabledevice
      // },
      // {
      //   title: 'Pays',
      //   path: PATH_DASHBOARD.country.list,
      //   icon: ICONS.country
      // }
    ]
  }

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //       ]
  //     },

  //     // MANAGEMENT : BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //       ]
  //     }
  //   ]
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
  //   ]
  // }
];

export const sidebarModConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Accueil',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'Les annonces',
        path: PATH_DASHBOARD.articles.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'Annonces actives', path: '/articles/active' },
          { title: 'Annonces en attente de validation', path: '/articles/pending' },
          { title: 'Annonces désactivées', path: '/articles/disable' },
        ]
      },
      // { title: 'CVthèque', path: '', icon: ICONS.analytics },
      {
        title: 'Liste des entreprises',
        path: PATH_DASHBOARD.entreprises.root,
        icon: ICONS.user,
        children: [
          { title: 'Toutes les entreprises', path: PATH_DASHBOARD.entreprises.touteEntreprise },
          { title: 'Les entreprises avec abonnement', path: PATH_DASHBOARD.entreprises.touteAvecEntreprise },
          { title: 'Les entreprises sans abonnement', path: PATH_DASHBOARD.entreprises.touteSansEntreprise }
        ]
      },

      // { title: 'Statistiques', path: '', icon: ICONS.chart },

      // MANAGEMENT : Subscription
     

      {
        title: 'Gestion des utilisateurs',
        path: PATH_DASHBOARD.accountSettings.root,
        icon: ICONS.accountSettings,
        children: [
          { title: 'Mon compte', path: PATH_DASHBOARD.accountSettings.changePassword },
          { title: 'Liste des utilisateurs', path: PATH_DASHBOARD.accountSettings.addNewMember }
        ]
      },
    ]
  }
];

export default sidebarConfig;
