import axios from 'axios';
import { print } from 'graphql';
import { apiGraphQLUrl } from '../config';

// --------------------------------- DEFAULT CONFIG   -------------------------------------
axios.defaults.baseURL = apiGraphQLUrl;
axios.defaults.headers.common['x-hasura-admin-secret'] = process.env.REACT_APP_HASURA_KEY;
// ----------------------------------------------------------------------------------------

const axiosInstance = axios.create();

export const setupInterceptors = ({ navigate, dispatch, logout }) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      localStorage.setItem('request_method', config.method);
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
      // eslint-disable-next-line no-unused-vars
      new Promise((resolve, reject) => {
        if (error.response.status === 403) {
          navigate('/403');
        } else if (error.response.status === 401 && localStorage.getItem('accessToken')) {
          dispatch(logout);
        }
        throw error.message;
      })
  );
};

// ///////////////////// GRAPHQL //////////////////
export const axiosGraphql = (body) => {
  return axiosInstance.post('/', { ...body, query: print(body.query) });
};
// ///////////////////////////////////////////////

export default axiosInstance;
