import gql from 'graphql-tag';

export const USER_FRAGEMENT = gql`
  fragment USER_FRAGEMENT on seformer_db_user {
    user_id
    firstname
    agency_id
    email
    role_id
    lastname
    password
    phone
    db_agency {
      agency_id
      name
      db_public_info_agency {
        dbMediaByMainLogoId {
          link_full
        }
      }
      db_administration_file {
        db_contact {
          email
        }
      }
      db_payements(where: { payment_status: { _eq: "paid" }, db_subscription_account: { is_active: { _eq: true } } }) {
        db_subscription_account {
          subscrition_account_id
          start_subscription_date
          end_subscription_date
          annonces_left
          db_subscription {
            subcription_id
            name
            description
            periode
            price
            number_seformer
          }
        }
      }
    }
    db_role {
      name
      role_id
      visible_name
    }
  }
`;
