import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosGraphql } from '../../utils/axios';
import { ADD_ARTICLE, VALIDATE_ARTICLE, EDIT_ARTICLE, DELETE_ARTICLE } from '../../graphql/mutations/article';
import { ARTICLE_DATA, ARTICLE_DATA_EDIT, GET_LIST_ARTICLES, GET_ARTICLE_INFO } from '../../graphql/queries/article';

const initialState = {
  isLoading: false,
  error: false,
  articles: [],
  articleCount: 0,
  article: {},
  filter: 'pending'
};

const slice = createSlice({
  name: 'Article',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getArticles(state, action) {
      state.isLoading = false;
      state.articles = action.payload;
    },

    getArticleCount(state, action) {
      state.isLoading = false;
      state.articleCount = action.payload;
    },

    getArticle(state, action) {
      state.isLoading = false;
      state.article = action.payload;
    },

    setFilter(state, action) {
      state.filter = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const getArticleData = createAsyncThunk('Article/getArticleData', async (agencyId, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({ query: ARTICLE_DATA, variables: { agency_id: agencyId } });
  dispatch(slice.actions.endLoading());
  return response.data || null;
});

export const getArticleDataEdit = createAsyncThunk('Article/getArticleDataEdit', async (reference, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({ query: ARTICLE_DATA_EDIT, variables: { reference } });
  dispatch(slice.actions.endLoading());
  return response.data || null;
});

export const getArticles = createAsyncThunk('Article/getArticles', async (props, { dispatch }) => {
  dispatch(slice.actions.startLoading());

  let status = { _neq: '' };
  if (props?.status && props?.status !== '') {
    status = { _eq: props?.status };
  }

  const response = await axiosGraphql({
    query: GET_LIST_ARTICLES,
    variables: {
      limit: props?.limit,
      offset: props?.offset,
      critere: props?.critere,
      search: props?.search && props?.search !== '' ? { _ilike: `%${props?.search}%` } : { _nlike: '' },
      status,
      agencyId: props?.agencyId && props?.agencyId !== 0 ? { _eq: props?.agencyId } : {}
    }
  });

  if (response?.status === 200) {
    dispatch(slice.actions.getArticles(response.data.data.seformer_db_article));
    dispatch(slice.actions.getArticleCount(response.data.data.seformer_db_article_aggregate.aggregate.count));
  }
  dispatch(slice.actions.endLoading());
  return response || null;
});

export const addArticle = createAsyncThunk('Article/addArticle', async (article, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({ query: ADD_ARTICLE, variables: { ...article } });
  dispatch(slice.actions.endLoading());
  return response || null;
});

export const editArticle = createAsyncThunk('Article/editArticle', async (payload, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({
    query: EDIT_ARTICLE,
    variables: {
      ...payload.article,
      article_life_duration_id: payload.life_duration.article_life_duration_id,
      _set: { ...payload.life_duration }
    }
  });
  dispatch(slice.actions.endLoading());
  return response || null;
});

export const editStatusArticle = createAsyncThunk('Article/editStatusArticle', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const { article, status } = data;
  const lifeDuration = {
    article_life_duration_id: article.db_article_life_duration.article_life_duration_id
  };
  const optionDuration = {
    news: {},
    sponsorised: {}
  };
  const articleInfo = await axiosGraphql({
    query: GET_ARTICLE_INFO,
    variables: {
      article_life_duration_id: lifeDuration.article_life_duration_id
    }
  });

  if (status === 'active') {
    if (article.db_article_option_paids.length) {
      const formatDuration = (duration) => {
        const digits = duration.match(/\d+/g);
        const letters = duration.match(/[a-zA-Z]+/g);
        const date = moment();
        let day = 0;
        switch (letters[0]) {
          case 'w':
            day = 7;
            break;
          case 'm':
            day = 30;
            break;
          case 'd':
            day = 1;
            break;
          default:
            break;
        }
        return date
          .add(day * digits, 'days')
          .hour(23)
          .minute(59)
          .second(59);
      };
      const options = {};
      (article?.db_article_option_paids || []).map((item) => {
        const index = item?.db_option?.db_option_type?.value;
        if (index && !options[index]) {
          options[index] = {
            ...item
          };
        }
        return item;
      });
      if (options && options?.news) {
        const option = options?.news;
        if (!option?.end_option_date) {
          optionDuration.news = {
            article_option_paid_id: option.article_option_paid_id,
            start_option_date: moment(),
            end_option_date: formatDuration(option.db_option.duration),
            is_active: true
          };
        }
      }
      if (options && options?.sponsorised) {
        const option = options?.sponsorised;
        if (!option?.end_option_date) {
          optionDuration.sponsorised = {
            article_option_paid_id: option.article_option_paid_id,
            start_option_date: moment(),
            end_option_date: formatDuration(option.db_option.duration),
            is_active: true
          };
        }
      }
    }
    if (!articleInfo.data?.data?.seformer_db_article_life_duration_by_pk?.desactivation_date) {
      let duration = 60; // 2 mois
      if (articleInfo.data?.data?.seformer_db_article_price_info) {
        duration = articleInfo.data?.data?.seformer_db_article_price_info[0]?.duration_length;
      }

      // desactivation_date
      lifeDuration.activation_date = moment();
      lifeDuration.desactivation_date = moment().add(duration, 'days').hour(23).minute(59).second(59);
    }
  }

  const response = await axiosGraphql({
    query: VALIDATE_ARTICLE,
    variables: {
      _setOptionDurationNews: optionDuration.news,
      _setOptionDurationSponsorised: optionDuration.sponsorised,
      _setLifeDuration: lifeDuration,
      status,
      article_id: article.article_id,
      article_life_duration_id: lifeDuration.article_life_duration_id,
      article_option_paid_id_news: optionDuration.news?.article_option_paid_id || 0,
      article_option_paid_id_sponsorised: optionDuration.sponsorised?.article_option_paid_id || 0
    }
  });
  dispatch(slice.actions.endLoading());
  return response || null;
});

export const deleteArticle = createAsyncThunk('Article/deleteArticles', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({ query: DELETE_ARTICLE, variables: { article_id: id } });
  const _return = (response?.status === 200 && response.data.data.seformer_db_article) || [];
  dispatch(slice.actions.endLoading());
  return _return;
});

export const deleteArticleMultiple = createAsyncThunk('Article/deleteMultiple', () => ({}));

export const setFilter = createAsyncThunk('Article/setFilter', async (filter, { dispatch }) => {
  dispatch(slice.actions.setFilter(filter));
  return filter;
});
