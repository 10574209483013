import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  degrees: [],
  degree: {}
};

const slice = createSlice({
  name: 'degree',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDegrees(state, action) {
      state.isLoading = false;
      state.degrees = action.payload;
    },

    getDegree(state, action) {
      state.isLoading = false;
      state.degree = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const getDegree = createAsyncThunk('degree/getDegree', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.get(`/api/degrees/${id}`);
  dispatch(slice.actions.getDegree(response.data));
  return response.data === undefined ? null : response.data;
});

// need to edit
export const getDegrees = createAsyncThunk(
  'degree/getDegrees',
  async ({ order = 'desc', orderBy = 'id', itemsPerPage = '100', name, page = 1 }, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(
      `/api/degrees?page=${page}&itemsPerPage=${itemsPerPage}&pagination=true&order%5B${orderBy}%5D=${order}${
        name && name.length ? `&name=${name}` : ''
      }`
    );
    dispatch(slice.actions.getDegrees(response.data['hydra:member']));
    return response === undefined ? null : response.data;
  }
);

export const addDegree = createAsyncThunk('degree/addDegree', async (degree, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.post('/api/degrees', degree);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateDegree = createAsyncThunk('degree/updateDegree', async ({ id, degree }, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.put(`/api/degrees/${id}`, degree);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteDegree = createAsyncThunk('degree/deleteDegree', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.delete(`/api/degrees/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

// eslint-disable-next-line arrow-body-style
export const deleteDegreeMultiple = createAsyncThunk('degree/deleteMultiple', (ids) => {
  return Promise.all(
    ids.map(async (id) => {
      const response = await axios.delete(`/api/degrees/${id}}`);
      if (response.data.error) {
        return null;
      }
      return response;
    })
  );
});

