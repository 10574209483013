export default function Pagination(theme) {
  return {
    MuiPaginationItem: {
      styleOverrides: {
      text:{
        border:'1px solid #f0efed!important',
      },
      ellipsis:{
        border:'0!important',
      },
        root: {
          color :'#637381',
          '&.Mui-selected': {
            fontWeight: theme.typography.fontWeightBold,
            border:'1px solid #00A998!important',
            backgroundColor:'#00A998',
            color:'#fff',
            '&:hover' :{
              backgroundColor:'#00A998',
               border:'1px solid #00A998!important',
            }
          }
        },
        textPrimary: {
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            '&:hover, &.Mui-focusVisible': {
              backgroundColor:'transparent!important',
            }
          }
        },
        outlined: {
        },
        outlinedPrimary: {
          '&.Mui-selected': {
          }
        },
      previousNext:{
         color:'#00A998',
         border:'0!important',
         '&:focus, &.Mui-focusVisible,&:visited': {
          outline :'none',
          background:'none!important',
          '&.Mui-selected':{
            background:'none!important',
            border:'0',
          }
         },
        '&.Mui-disabled': {
          color:'#637381',
          display:'none',
        }
      },
      }
    }
  };
}