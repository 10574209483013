import gql from 'graphql-tag';

export const ADD_ARTICLE = gql`
  mutation ADD_ARTICLE(
    $articleToAdd: ArticleInput = {}
    $payBy: PayByInput = {}
    $subscriptionAccount: SubscriptionAccountInput = {}
  ) {
    AddArticle(articleToAdd: $articleToAdd, payBy: $payBy, subscriptionAccount: $subscriptionAccount) {
      article_id
      payment_token
      order_summary {
        publication
        featured
        sponsored
        tva_rate
        tva_amount
      }
    }
  }
`;

export const EDIT_ARTICLE = gql`
  mutation EDIT_ARTICLE(
    $articleToEdit: ArticleEditInput = {}
    $payBy: PayByInput = {}
    $article_life_duration_id: Int!
    $_set: seformer_db_article_life_duration_set_input
  ) {
    EditArticle(articleToEdit: $articleToEdit, payBy: $payBy) {
      article_id
      payment_token
      order_summary {
        publication
        featured
        sponsored
        tva_rate
        tva_amount
      }
    }
    update_seformer_db_article_life_duration_by_pk(
      pk_columns: { article_life_duration_id: $article_life_duration_id }
      _set: $_set
    ) {
      article_life_duration_id
    }
  }
`;

export const VALIDATE_ARTICLE = gql`
  mutation UPDATE_ARTICLE_STATUS (
    $_setOptionDurationNews: seformer_db_article_option_paid_set_input
    $_setOptionDurationSponsorised: seformer_db_article_option_paid_set_input
    $_setLifeDuration: seformer_db_article_life_duration_set_input
    $status: String
    $article_id: Int!
    $article_life_duration_id: Int!
    $article_option_paid_id_news: Int!
    $article_option_paid_id_sponsorised: Int!
  ) {
    article: update_seformer_db_article_by_pk(pk_columns: { article_id: $article_id }, _set: { status: $status }) {
      article_id
    }
    lifeduration: update_seformer_db_article_life_duration_by_pk(
      pk_columns: { article_life_duration_id: $article_life_duration_id }
      _set: $_setLifeDuration
    ) {
      article_life_duration_id
    }
    option_paid: update_seformer_db_article_option_paid(
      where: { article_id: { _eq: $article_id } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
    news: update_seformer_db_article_option_paid_by_pk(
      pk_columns: { article_option_paid_id: $article_option_paid_id_news }
      _set: $_setOptionDurationNews
    ) {
      article_option_paid_id
    }
    sponsorised: update_seformer_db_article_option_paid_by_pk(
      pk_columns: { article_option_paid_id: $article_option_paid_id_sponsorised }
      _set: $_setOptionDurationSponsorised
    ) {
      article_option_paid_id
    }
  }
`;

export const DELETE_ARTICLE = gql`
  mutation DELETE_ARTICLE($article_id: Int!) {
    delete_seformer_db_article_by_pk(article_id: $article_id) {
      article_id
    }
  }
`;
