import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { axiosGraphql } from '../../utils/axios';
import { LEVELS } from '../../graphql/queries/level';

const initialState = {
  isLoading: false,
  error: false,
  levels: [],
  level: {}
};

const slice = createSlice({
  name: 'level',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getLevels(state, action) {
      state.isLoading = false;
      state.levels = action.payload;
    },

    getLevel(state, action) {
      state.isLoading = false;
      state.level = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const getLevel = createAsyncThunk('level/getLevel', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.get(`/api/levels/${id}`);
  dispatch(slice.actions.getLevel(response.data));
  return response.data === undefined ? null : response.data;
});

// need to edit
export const getLevels = createAsyncThunk('level/getLevels', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({ query: LEVELS }); // ?? error
  const _return =
    (response?.status === 200 && response.data.data[`${process.env.REACT_APP_HASURA_SCHEMA}db_level`]) || [];
  dispatch(slice.actions.endLoading());
  dispatch(slice.actions.getLevels(_return));
  return _return;
});

export const addLevel = createAsyncThunk('level/addLevels', async (level, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.post('/api/levels', level);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateLevel = createAsyncThunk('level/updateLevels', async ({ id, level }, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.put(`/api/levels/${id}`, level);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteLevel = createAsyncThunk('level/deleteLevels', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.delete(`/api/levels/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

export const deleteLevelMultiple = createAsyncThunk('level/deleteMultiple', (ids) =>
  Promise.all(
    ids.map(async (id) => {
      const response = await axios.delete(`/api/levels/${id}}`);
      if (response.data.error) {
        return null;
      }
      return response;
    })
  )
);
