import axios from 'axios';

export const checkErrorCode = (code, error) => error.includes(code);

export const truncateString = (string, length) => string.length > length ? `${string.substring(0, length)}...` : string;

export const refreshWebsiteData = () => {
  axios.defaults.baseURL = '';
  if (process.env.REACT_APP_WEBHOOK_BUILDER) 
    axios.post(`${process.env.REACT_APP_WEBHOOK_BUILDER}`);
};