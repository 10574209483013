import gql from 'graphql-tag';

export const ENTREPRISES = gql`
  query ENTREPRISES($limit: Int, $offset: Int, $search: String_comparison_exp) {
    seformer_db_agency(where: { name: $search }, limit: $limit, order_by: { name: asc }, offset: $offset) {
      agency_id
      name
      db_payements {
        db_subscription_account {
          annonces_left
          db_subscription {
            name
          }
          end_subscription_date
        }
      }
      db_public_info_agency {
        dbMediaByMainLogoId {
          link_full
          name
        }
      }
    }
    seformer_db_agency_aggregate(where: { name: $search }) {
      aggregate {
        count
      }
    }
  }
`;

export const ENTREPRISES_AVEC_ABONNEMENT = gql`
  query ENTREPRISES($limit: Int, $offset: Int, $search: String_comparison_exp) {
    seformer_db_agency(
      where: {
        name: $search
        db_payements: {
          is_abo: { _eq: true }
          db_payement_status: { name: { _eq: "paid" } }
          db_subscription_account: { end_subscription_date: { _gt: "now()" } }
        }
      }
      limit: $limit
      order_by: { name: asc }
      offset: $offset
    ) {
      agency_id
      name
      db_payements(limit: 1) {
        db_subscription_account {
          annonces_left
          db_subscription {
            name
          }
          end_subscription_date
        }
      }
      db_public_info_agency {
        dbMediaByMainLogoId {
          link_full
          name
        }
      }
    }
    seformer_db_agency_aggregate(
      where: {
        name: $search
        db_payements: { is_abo: { _eq: true }, db_subscription_account: { end_subscription_date: { _gt: "now()" } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ENTREPRISES_SANS_ABONNEMENT = gql`
  query ENTREPRISES($limit: Int, $offset: Int, $search: String_comparison_exp) {
    agency_null: seformer_db_agency(
      where: { name: $search, _not: { db_payements: {} } }
      limit: $limit
      order_by: { name: asc }
      offset: $offset
    ) {
      agency_id
      name
      db_public_info_agency {
        dbMediaByMainLogoId {
          link_full
          name
        }
      }
    }
    agency_null_count: seformer_db_agency_aggregate(where: { name: $search, _not: { db_payements: {} } }) {
      aggregate {
        count
      }
    }
    agency_sub: seformer_db_agency(
      where: {
        name: $search
        _not: { db_payements: { is_abo: { _eq: false } } }
        _and: {
          db_payements: { is_abo: { _eq: true }, db_subscription_account: { end_subscription_date: { _lte: "now()" } } }
        }
      }
      limit: $limit
      order_by: { name: asc }
      offset: $offset
    ) {
      agency_id
      name
      db_public_info_agency {
        dbMediaByMainLogoId {
          link_full
          name
        }
      }
    }
    agency_sub_count: seformer_db_agency_aggregate(
      where: {
        name: $search
        _not: { db_payements: { is_abo: { _eq: false } } }
        _and: {
          db_payements: { is_abo: { _eq: true }, db_subscription_account: { end_subscription_date: { _lte: "now()" } } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ENTREPRISE = gql`
  query AGENCY($agency_id: Int!) {
    agency: seformer_db_agency_by_pk(agency_id: $agency_id) {
      contact: dbContactByMainContactId {
        contact_id
        email
        firstname
        lastname
        phone
        fonction: db_fonction {
          name
          fonction_id
        }
      }
      administration_file: db_administration_file {
        administration_file_id
        facturation_address
        kbis
        db_media_kbis {
          media_id
          name
          link_full
        }
        siret
        social_reason
        contact: db_contact {
          contact_id
          email
          firstname
          lastname
          phone
          fonction: db_fonction {
            name
            fonction_id
          }
        }
      }
      name
      agency_type
      user_create
      db_subscription_accounts(where: { is_active: { _eq: true } }) {
        annonces_left
        end_subscription_date
        start_subscription_date
        subscrition_account_id
        is_active
        db_subscription {
          subcription_id
          active
          name
          number_seformer
          periode
          price
        }
      }
      db_agency_type {
        agency_type_id
        name
      }
      public_info: db_public_info_agency {
        public_info_id
        agency_id
        agency_description
        total_training_number
        training_sucess_rate
        people_trained_year
        website
        sector: db_sector {
          name
          sector_id
        }
        address: db_address {
          address_id
          address
          city: db_city {
            city_id
            name
            postal_code
            region: db_region {
              name
              region_id
            }
          }
        }
        logo: dbMediaByMainLogoId {
          media_id
          name
          link_full
        }
        medias {
          media_id
          link_full
        }
      }
      db_users {
        user_id
      }
    }
    subscription: seformer_db_subscription(order_by: { subcription_id: desc }) {
      subcription_id
      name
      number_seformer
      price
      periode
    }
    sector: seformer_db_sector(order_by: { sector_id: desc }) {
      name
      sector_id
    }
    city: seformer_db_city(order_by: { city_id: desc }) {
      name
      city_id
      postal_code
      db_region {
        region_id
      }
    }
    region: seformer_db_region(order_by: { region_id: desc }) {
      name
      region_id
    }
    fonction: seformer_db_fonction(order_by: { fonction_id: desc }) {
      name
      fonction_id
    }
    agency_type: seformer_db_agency_type(order_by: { agency_type_id: desc }) {
      name
      agency_type_id
    }
  }
`;

export const ENTREPRISE_SANS = gql`
  query AGENCY {
    subscription: ${process.env.REACT_APP_HASURA_SCHEMA}db_subscription(order_by: {subcription_id: desc}) {
      subcription_id
      name
      number_seformer
      price
      periode
    }
    sector: ${process.env.REACT_APP_HASURA_SCHEMA}db_sector(order_by: {sector_id: desc}) {
      name
      sector_id
    }
    city: ${process.env.REACT_APP_HASURA_SCHEMA}db_city(order_by: {city_id: desc}) {
      name
      city_id
      postal_code
      db_region {
        region_id
      }
    }
    region: ${process.env.REACT_APP_HASURA_SCHEMA}db_region(order_by: {region_id: desc}) {
      name
      region_id
    }
    fonction: ${process.env.REACT_APP_HASURA_SCHEMA}db_fonction(order_by: {fonction_id: desc}) {
      name
      fonction_id
    }
    agency_type: ${process.env.REACT_APP_HASURA_SCHEMA}db_agency_type(order_by: {agency_type_id: desc}) {
      name
      agency_type_id
    }
  }
`;

export const CHECK_ENTREPRISE = gql`
  query CHECK_AGENCY ($name: String) {
    agence: ${process.env.REACT_APP_HASURA_SCHEMA}db_agency(where: {name: {_ilike: $name}}) {
      agency_id
      name
    }
  }
`;
