import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { axiosGraphql } from '../../utils/axios';
import { REGIONS } from '../../graphql/queries/region';

const initialState = {
  isLoading: false,
  error: false,
  regions: [],
  region: {},
  countries: []
};

const slice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getRegions(state, action) {
      state.isLoading = false;
      state.regions = action.payload;
    },

    getRegion(state, action) {
      state.isLoading = false;
      state.region = action.payload;
    },

    getCountries(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const getRegion = createAsyncThunk('region/getRegion', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.get(`/api/regions/${id}`);
  dispatch(slice.actions.getRegion(response.data));
  return response.data === undefined ? null : response.data;
});

// need to edit
export const getRegions = createAsyncThunk('region/getRegions', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({ query: REGIONS }); // ?? error
  const _return =
    (response?.status === 200 && response.data.data[`${process.env.REACT_APP_HASURA_SCHEMA}db_region`]) || [];
  dispatch(slice.actions.endLoading());
  dispatch(slice.actions.getRegions(_return));
  return _return;
});

export const addRegion = createAsyncThunk('region/addRegion', async (region, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.post('/api/regions', region);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateRegion = createAsyncThunk('region/updateRegion', async ({ id, region }, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.put(`/api/regions/${id}`, region);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteRegion = createAsyncThunk('region/deleteRegion', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.delete(`/api/regions/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

// eslint-disable-next-line arrow-body-style
export const deleteRegionMultiple = createAsyncThunk('region/deleteMultiple', (ids) => {
  return Promise.all(
    ids.map(async (id) => {
      const response = await axios.delete(`/api/regions/${id}}`);
      if (response.data.error) {
        return null;
      }
      return response;
    })
  );
});

export const getCountries = createAsyncThunk('region/getCountries', async (_data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.get('/api/countries');
  dispatch(slice.actions.getCountries(response.data['hydra:member']));
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : response.data;
});
