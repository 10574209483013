import { useState } from 'react';
import { Box, Button, Container, Stack, Typography, Grid, Link } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';
import imageLogo from '../../assets/images/logo_seformer_page_moderator.png';
import burgerMenu from '../../assets/images/icone-menu.png';
import burgerfermer from '../../assets/images/close-button.png';
import { LoginForm } from '../../components/authentication/login';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import '../../components/authentication/login/LoginForm.css';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const [isShowMenu, toggleMenu] = useState(false);
  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };
  const srcImageLogo = imageLogo;
  return (
    <RootStyle title="Connexion - Seformer.re">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          Get started
        </Link>
      </AuthLayout> */}

      {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Bon retour parmi nous
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
    </MHidden> */}
      <div className="contentLogin">
        <div className="contentMenuConexionOuter">
          <Container>
            <div className="contentMenuConexion">
              <Grid md={3} item>
                <Link href={process.env.REACT_APP_FO_URL} className="content_logo">
                  <img className="logoSeformerConnexion" src={srcImageLogo} alt="Se former" />
                </Link>
              </Grid>
              <Grid md={9} item>
                <div className="main-menu ">
                  <nav>
                    <ul id="navigation">
                      <li>
                        <Link href={`${process.env.REACT_APP_FO_URL}/annonces`}>Formation</Link>
                      </li>
                      <li>
                        <Link href={`${process.env.REACT_APP_FO_URL}/annonces/?type=stage`}>Stage</Link>
                      </li>
                      <li>
                        <Link href="https://www.domtomjob.com/" target="_blank">
                          Emploi
                        </Link>
                      </li>
                      <li className="publie_annonce">
                        <Link href={`${process.env.REACT_APP_FO_URL}/Abonnement`}>
                          Espace <br /> pro
                        </Link>
                      </li>
                      <li>
                        <Link href={`${process.env.REACT_APP_FO_URL}/Creer-mon-compte`}>S’inscrire</Link>
                      </li>
                      <li>
                        <Link href="/">Se connecter</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Grid>
            </div>
            <div className="contentMenuConexionMobile">
              <Button
                className="ContentMenuburger"
                onClick={() => {
                  toggleMenu(!isShowMenu);
                }}
              >
                <img src={burgerMenu} className="Menuburger" alt="Se former" />
              </Button>
              {isShowMenu && (
                <div className="contentMenuNavMobile">
                  <Button
                    className="Contentclose"
                    onClick={() => {
                      toggleMenu(!isShowMenu);
                    }}
                  >
                    <img src={burgerfermer} className="Menuclose" alt="close" />
                  </Button>
                  <nav>
                    <ul>
                      <li>
                        <Link href={`${process.env.REACT_APP_FO_URL}/annonces`}>Formation</Link>
                      </li>
                      <li>
                        <Link href={`${process.env.REACT_APP_FO_URL}/annonces/?type=stage`}>Stage</Link>
                      </li>
                      <li>
                        <Link href="https://www.domtomjob.com/" target="_blank">
                          Emploi
                        </Link>
                      </li>
                      <li className="publie_annonce">
                        <Link href={`${process.env.REACT_APP_FO_URL}/Abonnement`}>Espace pro</Link>
                      </li>
                      <li>
                        <Link href={`${process.env.REACT_APP_FO_URL}/Creer-mon-compte`}>S’inscrire</Link>
                      </li>
                      <li>
                        <Link href="/">Se connecter</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
              <Grid md={12} xs={12} item>
                <Link href={`${process.env.REACT_APP_FO_URL}`}>
                  <img src={srcImageLogo} className="logoSeformerMobile" alt="Se former" />
                </Link>
              </Grid>
            </div>
          </Container>
        </div>
        <Container>
          <ContentStyle className="contentBlocFormOuter">
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }} className="TitreEspaceClient">
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Espace client
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }} className="ContentDeuxBlocLogin">
              <Box sx={{ flexGrow: 1 }} className="ContentBlocForm">
                {method === 'firebase' && <AuthFirebaseSocials />}

                {method !== 'auth0' ? (
                  <LoginForm />
                ) : (
                  <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                    Connexion
                  </Button>
                )}
              </Box>
              <Box sx={{ flexGrow: 1 }} className="ContentTextLogin">
                <Typography className="TextTitreContact">
                  Si vous rencontrez des problèmes de connexion à votre espace, merci de contacter notre Responsable
                  Commerciale
                </Typography>
                <Typography>
                  <strong className="LabelFormulaireLogin">Contact : </strong> Mme Aurélie MARIOTTI
                </Typography>
                <Typography>
                  <strong className="LabelFormulaireLogin">Mail : </strong> aurelie.mariotti@antennereunion.fr
                </Typography>
                <Typography>
                  <strong className="LabelFormulaireLogin">Tel : </strong> 06 92 43 91 01
                </Typography>
                <Typography>
                  <strong className="LabelFormulaireLogin">Fax : </strong> 0262482817
                </Typography>
              </Box>
            </Stack>
          </ContentStyle>
        </Container>
      </div>
    </RootStyle>
  );
}
