import gql from 'graphql-tag';
import { USER_FRAGEMENT } from '../fragments/user';

export const USERS = gql`
  query USERS($order_by: [db_user_order_by!] = { id: asc }) {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_user(order_by: $order_by) {
      ...USER_FRAGEMENT
    }
  }
  ${USER_FRAGEMENT}
`;

export const USER = gql`
  query USER($id: Int!) {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_user_by_pk(user_id: $id) {
      ...USER_FRAGEMENT
    }
  }
  ${USER_FRAGEMENT}
`;

export const USERBYMAIL = gql`
  query USERBYMAIL($email: String!) {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_user(where: {email: {_eq: $email}}) {
      ...USER_FRAGEMENT
    }
  }
  ${USER_FRAGEMENT}
`;

export const USERLIST = gql`
  query USERLIST($agency_id: Int!) {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_user(order_by: {firstname: asc, lastname: asc}, where: {agency_id: {_eq: $agency_id}}) {
      user_id
      firstname
      lastname
      email
      db_role {
        visible_name
      }
    }
  }
`;

export const NEWUSER = gql`
  query NEWUSER {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_fonction(order_by: {name: asc}) {
      fonction_id
      name
    }
    ${process.env.REACT_APP_HASURA_SCHEMA}db_role(order_by: {name: asc}) {
      role_id
      name
      visible_name
    }
  }
`;

export const MODERATORLIST = gql`
  query MODERATORLIST($role_id: Int!) {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_user(order_by: {firstname: asc, lastname: asc}, where: {role_id: {_eq: $role_id}}) {
      user_id
      firstname
      lastname
      email
      db_role {
        visible_name
      }
    }
  }
`;