import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Button, Drawer, Typography } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../routes/paths';
// components
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig, { sidebarModConfig } from './SidebarConfig';
// style
import { useStyles } from '../../assets/styles/DashboardSidebar.styles';
import btnReturn from '../../assets/images/btnReturn.png';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// const DocStyle = styled('div')(({ theme }) => ({
//   padding: theme.spacing(2.5),
//   borderRadius: theme.shape.borderRadiusMd,
//   backgroundColor:
//     theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.primary.lighter
// }));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  // const user = useSelector((state) => state.userGraphql.user);
  const { user } = useAuth();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
    >
    <Button className={classes.closeSidebar} onClick={onCloseSidebar}>X</Button>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Typography variant="h3" color="primary" className={classes.TitreEspaceClient}>
          {user?.IsModerator ? 'Espace Admin' : 'Espace Client'}
        </Typography>
      </Box>
      <Box sx={{ mb: 2, mx: 2.5 }}>
        
          <AccountStyle className={classes.SidebarLogo}>
          <Link underline="none" component={RouterLink} to="">
            <MyAvatar className={classes.SidebarAvatar} />
          </Link>
            <Box sx={{ ml: 2 }} className={classes.infoAvatar}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }} className={classes.titleinfoAvatar}>
                {user && `${user.firstname} ${user.lastname}`}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} className={classes.roleAvatar}>
                {(user && user.db_agency?.name) || (user && user.db_role?.visibleName)}
              </Typography>
            </Box>
          </AccountStyle>
        
      </Box>
      <Box sx={{ mb: 2, mx: 2.5 }}  style={{margin:'0', display:'flex', justifyContent:'center' }}>
        <Button onClick={() => navigate(PATH_DASHBOARD.articles.newArticle)} className={classes.CTAannonce}>
          Publier une annonce
        </Button>
      </Box>

      <NavSection navConfig={user?.IsModerator ? sidebarModConfig : sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 5 }}>
        <Link href="https://www.domtomjob.com/" target="_blank" className={classes.btnReturn}>
          <img src={btnReturn} alt="Retourner au site Domtomjob" /> Retourner au site Domtomjob
        </Link>
        <Link href="https://www.seformer.re/" target="_blank" className={classes.btnReturn}>
          <img src={btnReturn} alt="Retourner au site Seformer.re" /> Retourner au site Seformer.re
        </Link>
      </Box>
      <Box sx={{ px: 2.5, pb: 3, mb: 10 }}>
        <Button
          fullWidth
          to={PATH_AUTH.logout}
          variant="contained"
          component={RouterLink}
          className={classes.btnSidebarSeDeconnecter}
        >
          Se déconnecter
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
