import gql from 'graphql-tag';
import { REGION_FRAGMENT } from '../fragments/region';

export const REGIONS = gql`
  query REGIONS {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_region(order_by: {region_id: asc}) {
      ...REGION_FRAGMENT
    }
  }
  ${REGION_FRAGMENT}
`;
