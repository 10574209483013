import gql from 'graphql-tag';
import { ARTICLE_LIST_FRAGMENT } from '../fragments/article';

export const ARTICLE_DATA = gql`
  query ARTICLE_DATA($agency_id: Int!) {
    seformer_db_option(where: { active: { _eq: true } }, order_by: { name: asc, price: asc }) {
      option_id
      name
      duration
      price
      type
    }
    seformer_db_region(order_by: { name: asc }) {
      region_id
      name
    }
    seformer_db_city(order_by: { name: asc }) {
      city_id
      name
      db_region {
        region_id
        name
      }
    }
    seformer_db_training_type(order_by: { name: asc }) {
      type_id
      name
    }
    seformer_db_sector(order_by: { name: asc }) {
      sector_id
      name
    }
    seformer_db_degree(order_by: { name: asc }) {
      degree_id
      name
    }
    seformer_db_level(order_by: { name: asc }) {
      level_id
      name
    }
    seformer_db_public_admis(order_by: { name: asc }) {
      public_admis_id
      name
    }
    seformer_db_mobilization_device(order_by: { name: asc }) {
      mobilization_device_id
      name
    }
    seformer_db_agency(where: { agency_id: { _eq: $agency_id } }) {
      agency_id
      name
      db_administration_file {
        facturation_address
        db_contact {
          email
        }
      }
      db_users {
        email
      }
    }
    seformer_db_agencies: seformer_db_agency(order_by: { name: asc }) {
      agency_id
      name
      db_subscription_accounts(where: { is_active: { _eq: true } }) {
        subscrition_account_id
        start_subscription_date
        end_subscription_date
        annonces_left
        db_subscription {
          subcription_id
          name
          description
          periode
          price
          number_seformer
        }
      }
      db_administration_file {
        facturation_address
        db_contact {
          email
        }
      }
      db_users {
        email
      }
    }
  }
`;

export const ARTICLE_DATA_EDIT = gql`
  query ARTICLE_DATA_EDIT($reference: String!) {
    seformer_db_article(where: { reference: { _eq: $reference } }) {
      status
      article_id
      title
      contact_recrutment_email
      level_id
      sector
      info_agency
      db_payements {
        payment_id
      }
      db_training_articles {
        training_article_id
        goals
        programm
        job_targeted
        start_date
        end_date
        success_rate
        training_hours
        training_days
        training_months
        degree_obtained
        db_city {
          city_id
          db_region {
            region_id
          }
        }
        db_training_article_mobilization_devices {
          mobilization_device_id
        }
        db_training_article_public_admis {
          public_admis_id
        }
      }
      db_training_article_types {
        type_id
      }
      option_feature: db_article_option_paids(
        order_by: { article_option_paid_id: desc }
        where: { db_option: { type: { _eq: news } }, db_payement: { payment_status: { _neq: "failed" } } }
      ) {
        article_option_paid_id
        is_active
        db_option {
          option_id
        }
      }
      option_sponsorised: db_article_option_paids(
        order_by: { article_option_paid_id: desc }
        where: { db_option: { type: { _eq: sponsorised } }, db_payement: { payment_status: { _neq: "failed" } } }
      ) {
        article_option_paid_id
        is_active
        db_option {
          option_id
        }
      }
      db_article_life_duration {
        article_life_duration_id
        desactivation_date
      }
      db_agency {
        agency_id
        name
        db_administration_file {
          facturation_address
          db_contact {
            email
          }
        }
        db_users {
          email
        }
      }
      db_article_option_paids {
        article_option_paid_id
        db_option {
          option_id
          duration
          db_option_type {
            value
          }
        }
        end_option_date
      }
    }
    seformer_db_option(where: { active: { _eq: true } }, order_by: { name: asc, price: asc }) {
      option_id
      name
      duration
      price
      type
    }
    seformer_db_region(order_by: { name: asc }) {
      region_id
      name
    }
    seformer_db_city(order_by: { name: asc }) {
      city_id
      name
      db_region {
        region_id
        name
      }
    }
    seformer_db_training_type(order_by: { name: asc }) {
      type_id
      name
    }
    seformer_db_sector(order_by: { name: asc }) {
      sector_id
      name
    }
    seformer_db_degree(order_by: { name: asc }) {
      degree_id
      name
    }
    seformer_db_level(order_by: { name: asc }) {
      level_id
      name
    }
    seformer_db_public_admis(order_by: { name: asc }) {
      public_admis_id
      name
    }
    seformer_db_mobilization_device(order_by: { name: asc }) {
      mobilization_device_id
      name
    }
  }
`;

export const GET_ARTICLES = gql`
  query GET_ARTICLES($agency_id: Int!) {
    seformer_db_article(where: { agency_id: { _eq: $agency_id } }, order_by: { date_create: desc }) {
      ...ARTICLE_LIST_FRAGMENT
    }
  }
  ${ARTICLE_LIST_FRAGMENT}
`;

export const GET_LIST_ARTICLES = gql`
  query GET_ARTICLES(
    $limit: Int
    $offset: Int
    $search: String_comparison_exp
    $status: String_comparison_exp
    $agencyId: Int_comparison_exp
  ) {
    seformer_db_article(
      where: { title: $search, status: $status, agency_id: $agencyId }
      limit: $limit
      offset: $offset
      order_by: { date_create: desc }
    ) {
      ...ARTICLE_LIST_FRAGMENT
    }
    seformer_db_article_aggregate(where: { title: $search, status: $status, agency_id: $agencyId }) {
      aggregate {
        count
      }
    }
  }
  ${ARTICLE_LIST_FRAGMENT}
`;

export const GET_ARTICLE_INFO = gql`
  query GET_ARTICLE_INFO($article_life_duration_id: Int!) {
    seformer_db_article_price_info(where: { is_active: { _eq: true } }) {
      duration_length
    }
    seformer_db_article_life_duration_by_pk(article_life_duration_id: $article_life_duration_id) {
      activation_date
      desactivation_date
    }
  }
`;
