import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosGraphql } from '../../utils/axios';
import {
  ENTREPRISES,
  ENTREPRISES_AVEC_ABONNEMENT,
  ENTREPRISES_SANS_ABONNEMENT,
  ENTREPRISE,
  ENTREPRISE_SANS,
  CHECK_ENTREPRISE
} from '../../graphql/queries/entreprise';
import {
  UPSERTAGENCY,
  UPDATEAGENCY,
  UPSERTCONTACT,
  UPSERTAGENCYADMINFILE,
  UPDATEAGENCYADMINFILE,
  UPSERTPUBLICINFO,
  UPLOADFILE,
  UPSERTSUBSCRIPTION,
  UPDATESUBSCRIPTION,
  UPDATE_MEDIA_PUBLIC_AGENCY
} from '../../graphql/mutations/ficheentreprise';
import { ADD_USER } from '../../graphql/mutations/user';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  entrepriseCount: 0,
  entreprises: [],
  entreprise: {},
  option: {}
};

const slice = createSlice({
  name: 'Entreprise',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ENTREPRISES
    getEntreprise(state, action) {
      state.isLoading = false;
      state.entreprise = action.payload;
    },

    // GET ENTREPRISES
    getEntreprises(state, action) {
      state.isLoading = false;
      state.entreprises = action.payload;
    },

    // GET ENTREPRISES COUNT
    getEntrepriseCount(state, action) {
      state.isLoading = false;
      state.entrepriseCount = action.payload;
    },

    setAgencyOption(state, action) {
      state.option = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const uploadFileEntreprise = createAsyncThunk('Entreprise/uploadFileEntreprise', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const res = await axiosGraphql({ query: UPLOADFILE, variables: { ...data } });
  return res.data?.data?.UploadFile;
});

// ----------------------------------------------------------------------
export const getEntreprises = createAsyncThunk(
  'Entreprise/getEntreprises',
  async ({ limit, offset, critere, search }, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    let QUERY = ENTREPRISES;
    if (critere === 1) QUERY = ENTREPRISES_AVEC_ABONNEMENT;
    if (critere === 2) QUERY = ENTREPRISES_SANS_ABONNEMENT;
    const res = await axiosGraphql({
      query: QUERY,
      variables: { limit, offset, search: search || search !== '' ? { _ilike: `%${search}%` } : { _nlike: '' } }
    });
    let data = [];
    let count = 0;
    if (res.data?.data?.agency_null && res.data?.data?.agency_sub) {
      data = [...res.data?.data?.agency_null, ...res.data?.data?.agency_sub];
      count = res.data?.data?.agency_null_count.aggregate?.count + res.data?.data?.agency_sub_count.aggregate?.count;
    } else if (res.data?.data?.seformer_db_agency) {
      data = res.data?.data?.seformer_db_agency;
      count = res.data?.data?.seformer_db_agency_aggregate?.aggregate?.count || 0;
    }
    dispatch(slice.actions.getEntreprises(data));
    dispatch(slice.actions.getEntrepriseCount(count));
  }
);

// ---------------------------------------------------------------------
export const setAgencyOption = createAsyncThunk('agency/getAgency', async (agencyId, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axiosGraphql({
    query: agencyId ? ENTREPRISE : ENTREPRISE_SANS,
    variables: { agency_id: agencyId }
  });
  const data = (response?.status === 200 && response.data.data) || {};
  dispatch(slice.actions.setAgencyOption(data));
  dispatch(slice.actions.endLoading());
});

// ----------------------------------------------------------------------

export const upsertAgency = createAsyncThunk('agency/upsertAgency', async (data, { dispatch }) => {
  const upsertResponse = { status: 200, messages: 'Succès' };
  dispatch(slice.actions.startLoading());
  const { upsertOptions, oldValues } = data;
  const isUpAgencyName = data?.entreprise_societe_name === oldValues?.name;

  // check before save
  const checkAgency = await axiosGraphql({
    query: CHECK_ENTREPRISE,
    variables: {
      name: isUpAgencyName ? '' : data?.entreprise_societe_name
    }
  });
  if (checkAgency.data?.data?.agence?.length) {
    upsertResponse.status = 400;
    if (checkAgency.data?.data?.agence?.length) upsertResponse.messages = 'Nom entreprise déjà utilisé';
    dispatch(slice.actions.endLoading());
    return upsertResponse;
  }

  /**
   * Traiter les données en fonction de upsertOptions :
   * -> des données sont à mettre à jour, d'autres sont à ajouter si n'existent pas encore
   * */
  const optionAgency = {
    agencyId: upsertOptions?.agencyId,
    contactId: false,
    adminFileId: false,
    publicInfoId: false,
    subscriptionId: false
  };

  // Sauvegarde Contact Agence ---------------
  const _agencyContactAdministrationFile = {
    fonction_id: data?.entreprise_facture_contact_fonction || null,
    lastname: data?.entreprise_facture_contact_nom || '',
    firstname: data?.entreprise_facture_contact_prenom || '',
    email: data?.entreprise_facture_contact_email || '',
    phone: data?.entreprise_facture_contact_phone || ''
  };
  const _agencyContact = {
    fonction_id: (data?.entreprise_contact_fonction && Number(data?.entreprise_contact_fonction)) || null,
    lastname: data?.entreprise_contact_nom || '',
    firstname: data?.entreprise_contact_prenom || '',
    email: data?.entreprise_email || '',
    phone: data?.entreprise_phone || ''
  };
  const responseContactAdministrationFile = await axiosGraphql({
    query: UPSERTCONTACT,
    variables: {
      contactInfo: { ..._agencyContactAdministrationFile, contact_id: upsertOptions.existContactAdministrationFile },
      contact: { ..._agencyContact, contact_id: upsertOptions.existContactAgency }
    }
  });
  if (responseContactAdministrationFile.data?.errors?.length) {
    upsertResponse.status = 400;
    upsertResponse.messages = responseContactAdministrationFile.data.errors;
  }
  const contactAdministrationFileId =
    responseContactAdministrationFile.data?.data?.contactInfo?.contact_id ||
    upsertOptions.existContactAdministrationFile;
  const contactAgencyId =
    responseContactAdministrationFile.data?.data?.contact?.contact_id || upsertOptions.existContactAgency;

  // Sauvegarde Ficher d'administration Agence ---------------
  const _agencyAdminFile = {
    kbis: data?.entreprise_facture_kbis || null,
    social_reason: data?.entreprise_facture_raison_social || '',
    siret: data?.entreprise_facture_siret,
    facturation_address: {
      address: data?.entreprise_facture_adresse_siege || '',
      region: data?.entreprise_facture_adresse_complement || '',
      city: data?.entreprise_facture_ville || '',
      country: data?.entreprise_facture_pays || '',
      postalCode: (data?.entreprise_facture_cp && Number(data.entreprise_facture_cp)) || 97400
    }
  };

  const adminFile = {};
  if (upsertOptions.existAdministrationFile) {
    adminFile.administration_file_id = upsertOptions.existAdministrationFile;
  }
  const responseAgencyAdminFile = await axiosGraphql({
    query: (upsertOptions.existAdministrationFile && UPDATEAGENCYADMINFILE) || UPSERTAGENCYADMINFILE,
    variables: {
      adminFileInfo: {
        ..._agencyAdminFile,
        facturation_contact_id: contactAdministrationFileId,
        administration_file_id: upsertOptions.existAdministrationFile
      },
      ...adminFile
    }
  });
  if (responseAgencyAdminFile.data?.errors?.length) {
    upsertResponse.status = 400;
    upsertResponse.messages = responseAgencyAdminFile.data.errors;
  }
  const administrationFileId =
    responseAgencyAdminFile?.data?.data?.insert_seformer_db_administration_file_one?.administration_file_id ||
    upsertOptions.existAdministrationFile;

  // Sauvegarde Agence ---------------
  const _agency = {
    name: data?.entreprise_societe_name || '',
    administration_file_id: administrationFileId,
    main_contact_id: contactAgencyId,
    user_create: data?.entreprise_user_create_id,
    user_update: data?.entreprise_admin_id,
    agency_type: (data?.entreprise_type_entreprise && Number(data?.entreprise_type_entreprise)) || 0,
    isActive: true
  };
  const agency = {};
  if (isUpAgencyName) {
    delete _agency.name;
  }
  if (upsertOptions.agencyId) {
    agency.agency_id = upsertOptions.agencyId;
  }
  const responseUpdAgency = await axiosGraphql({
    query: (upsertOptions.agencyId && UPDATEAGENCY) || UPSERTAGENCY,
    variables: { agency: { ..._agency, agency_id: upsertOptions.agencyId }, ...agency }
  });
  if (responseUpdAgency.data?.errors?.length) {
    upsertResponse.status = 400;
    upsertResponse.messages = responseUpdAgency.data.errors;
  }
  const agencyId = responseUpdAgency?.data?.data?.insert_seformer_db_agency_one?.agency_id || upsertOptions.agencyId;

  // subscription account agency
  if (data?.subscription?.subcription_id) {
    const isNewSubscription =
      data.subscription.subcription_id !== data.subscription_account?.db_subscription?.subcription_id;

    const isSubscriptionChange =
      data.subscription_account?.annonces_left !== data.entreprise_abonnement_credit ||
      data.entreprise_abonnement_date_debut !== new Date(data.subscription_account.start_subscription_date);

    // check date
    const date = data?.entreprise_abonnement_date_debut && new Date(data?.entreprise_abonnement_date_debut);
    const dateFin = date || new Date(); // à partir de la date de création
    if (data?.subscription?.periode === 'annuel') {
      dateFin.setYear(dateFin.getFullYear() + 1);
    } else if (data?.subscription?.periode === 'semestriel') {
      dateFin.setMonth(dateFin.getMonth() + 6);
    } else if (data?.subscription?.periode === 'mensuel') {
      dateFin.setMonth(dateFin.getMonth() + 1);
    }
    const dateDebut =
      (data?.entreprise_abonnement_date_debut && new Date(data?.entreprise_abonnement_date_debut)) || new Date();

    // --------------------------------------- NEW SUB
    if (isNewSubscription) {
      const dataPayement = {
        date_payement: 'now()',
        addresss_facturation: {
          address: data?.entreprise_facture_adresse_siege || '',
          region: data?.entreprise_facture_adresse_complement || '',
          city: data?.entreprise_facture_ville || '',
          country: data?.entreprise_facture_pays || '',
          postalCode: (data?.entreprise_facture_cp && Number(data.entreprise_facture_cp)) || 97400
        },
        agency_id: agencyId,
        agency_name_facturation: data?.entreprise_societe_name || '',
        email: data?.entreprise_facture_contact_email,
        is_abo: true,
        is_one_shot_fo: false,
        reference: Math.floor(Math.random() * 1000000000),
        total_price: data?.subscription?.price,
        payment_status: 'paid',
        payment_method: 'AF',
        user_create: data?.entreprise_user_create_id,
        user_update: data?.entreprise_user_create_id
      };

      const dataSubscriptionAccount = {
        db_subscription_account: {
          data: {
            is_active: true,
            agency_id: agencyId,
            subcription_id: data?.subscription?.subcription_id,
            annonces_left: data?.entreprise_abonnement_credit,
            start_subscription_date: dateDebut.toISOString(),
            end_subscription_date: dateFin.toISOString()
          }
        }
      };

      const responseSubscription = await axiosGraphql({
        query: UPSERTSUBSCRIPTION,
        variables: {
          payment: { ...dataPayement, ...dataSubscriptionAccount },
          desactiveId: data?.subscription_account?.subscrition_account_id || 0
        }
      });
      optionAgency.subscriptionId =
        responseSubscription?.data?.data?.insert_seformer_db_subscription_account_one?.subscrition_account_id;
      if (responseSubscription.data?.errors?.length) {
        upsertResponse.status = 400;
        upsertResponse.messages = responseSubscription.data.errors;
      }
    } else if (isSubscriptionChange) {
      const dataSubscriptionAccount = {
        annonces_left: data?.entreprise_abonnement_credit,
        start_subscription_date: dateDebut.toISOString(),
        end_subscription_date: dateFin.toISOString()
      };
      await axiosGraphql({
        query: UPDATESUBSCRIPTION,
        variables: {
          subscriptionAccountId: data.subscription_account.subscrition_account_id,
          subscriptionAccount: { ...dataSubscriptionAccount }
        }
      });
    }
  }

  // Sauvegarde user
  if (!upsertOptions.agencyId) {
    const userdata = {
      email: data?.entreprise_email || '',
      firstname: data?.entreprise_contact_prenom || '',
      lastname: data?.entreprise_contact_nom || '',
      phone: data?.entreprise_phone || '',
      is_active: true,
      role_id: 2,
      agency_id: agencyId,
      username: ((name) => {
        const n = name.replace(/\s+/g, '');
        return n.toLowerCase();
      })(data?.entreprise_societe_name)
    };
    const resUser = await axiosGraphql({ query: ADD_USER, variables: userdata });
    if (resUser.data?.errors?.length) {
      upsertResponse.status = 400;
      upsertResponse.messages = resUser.data.errors;
    }
  }

  // Mise à jour Information Publique Agence ---------------
  // Faire un Upsert pour le publicinfo en fonction de id_agency
  const _publicInfo = {
    db_address: {
      data: {
        address: data?.entreprise_adresse || '',
        city_id: (data?.entreprise_ville && Number(data?.entreprise_ville)) || null
      }
    },
    main_logo_id: data?.main_logo_id,
    total_training_number: data?.entreprise_nb_formation || 0,
    training_sucess_rate: data?.entreprise_taux_reussite || 0,
    people_trained_year: data?.entreprise_nb_personne_forme_par_an || 0,
    website: data?.entreprise_site_web || '',
    agency_description: data?.entreprise_presentation_entreprise || '',
    agency_id: agencyId,
    sector_id: (data?.entreprise_domaine_activite && Number(data?.entreprise_domaine_activite)) || null
  };
  const responseUpdPublicInfo = await axiosGraphql({
    query: UPSERTPUBLICINFO,
    variables: { publicInfo: _publicInfo, _set: { ..._publicInfo.db_address.data }, id_agency: _publicInfo.agency_id }
  });
  if (responseUpdPublicInfo.data?.errors?.length) {
    upsertResponse.status = 400;
    upsertResponse.messages = responseUpdPublicInfo.data.errors;
  }

  // UPDATE MEDIA PUBLIC AGENCY
  await axiosGraphql({
    query: UPDATE_MEDIA_PUBLIC_AGENCY,
    variables: {
      media_id: data.logos.map((item) => item.image_id),
      agency_public_id: responseUpdPublicInfo.data?.data?.insert_seformer_db_public_info_agency_one?.public_info_id
    }
  });

  upsertResponse.agency_id = upsertOptions?.agencyId || optionAgency.agencyId;

  dispatch(slice.actions.endLoading());
  return upsertResponse;
});
