import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../routes/paths';
import { resetUserState } from '../redux/slices/user';
import axios, { axiosGraphql } from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { LOGIN, PWD_REQUEST, RESET_PWD } from '../graphql/queries/login';
import { USER } from '../graphql/queries/user';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user: { ...user, IsModerator: user?.db_role?.name === 'admin' }
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user: { ...user, IsModerator: user?.db_role?.name === 'admin' }
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          const id = setSession(accessToken);

          const response = await axiosGraphql({ query: USER, variables: { id } });
          const data = response.data.data[`${process.env.REACT_APP_HASURA_SCHEMA}db_user_by_pk`];
          data.displayName = (data.firstname && `${data.firstname} ${data.lastname}`) || data.lastname;
          data.role = data.db_role.visible_name;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: { ...data, IsModerator: data?.db_role?.name === 'admin' }
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  // login method
  const login = async (email, password) => {
    const response = await axiosGraphql({
      query: LOGIN,
      variables: { email, password }
    });
    if (response.data.errors)
      return {
        status: 401,
        message: response.data.errors[0].message
      };

    const data = response.data.data.Login;
    const { token } = data;
    setSession(token);

    data.displayName = (data.firstname && `${data.firstname} ${data.lastname}`) || data.lastname;
    dispatch({
      type: 'LOGIN',
      payload: {
        user: { ...data, isAuthenticated: true, IsModerator: data?.role === 'admin' }
      }
    });
    return navigate(PATH_DASHBOARD.root);
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch(resetUserState());
    dispatch({ type: 'LOGOUT' });
  };

  const forgotPassword = async (emailData) => {
    // await axios.post('/api/users/password/reset/request',  );
    const res = await axiosGraphql({
      query: PWD_REQUEST,
      variables: { email: emailData.email }
    });

    if (res && res.status === 200) {
      if (res.data && res.data.data && res.data.data.sendEmailResetPwd && res.data.data.sendEmailResetPwd.mailSent) {
        return {
          status: 200,
          message: 'Mail envoyé !'
        };
      }
      if (res.data && res.data.data && res.data.data.sendEmailResetPwd && !res.data.data.sendEmailResetPwd.mailSent) {
        console.log('ERREUR : ', res.data.data.sendEmailResetPwd.errors);
        return {
          status: 400,
          message: "Erreur, vérifiez l'adresse e-mail saisie et réessayez !"
        };
      }
      if (res.data && res.data.errors) {
        return {
          status: 401,
          message: res.data.errors[0].message
        };
      }
      return {
        status: 403,
        message: "Erreur d'accès au service"
      };
    }
    return {
      status: 403,
      message: "Erreur d'accès au service"
    };
  };

  const resetPassword = async (data) => {
    const { password, token, username } = data;

    const res = await axiosGraphql({
      query: RESET_PWD,
      variables: { password, token, username }
    });

    if (res && res.status === 200) {
      if (res.data && res.data.data && res.data.data.SetUserPassword && res.data.data.SetUserPassword.status === 200) {
        // reset success
        return {
          status: 200,
          message: 'Votre mot de passe a été réinitialisé'
        };
      }
      if (res.data && res.data.data && res.data.data.SetUserPassword && res.data.data.SetUserPassword.status === 400) {
        // reset failed
        console.log(res.data.data.SetUserPassword.message);
        return {
          status: 400,
          message: 'Erreur, impossible de réinitialiser le mot de passe'
        };
      }
      if (res.data && res.data.errors) {
        return {
          status: 401,
          message: res.data.errors[0].message
        };
      }
      return {
        status: 403,
        message: "Erreur d'accès au service"
      };
    }
    return {
      status: 403,
      message: "Erreur d'accès au service"
    };
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
