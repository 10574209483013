import gql from 'graphql-tag';
import { LEVEL_FRAGMENT } from '../fragments/level';

export const LEVELS = gql`
  query LEVELS {
    ${process.env.REACT_APP_HASURA_SCHEMA}db_level(order_by: {level_id: asc}) {
      ...LEVEL_FRAGMENT
    }
  }
  ${LEVEL_FRAGMENT}
`;
