import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DocsLayout from '../layouts/docs';
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { pathname } = useLocation();
  // const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...{
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            }
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'logout', element: <Logout /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> }
      ]
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        {
          path: 'analytics',
          element: <GeneralAnalytics />
        },
        {
          path: 'articles',
          children: [
            { path: '/', element: <Navigate to="/articles/active" replace /> },
            { path: '/new', element: <ArticleCreate /> },
            { path: '/:ref/edit', element: <ArticleEdit /> },
            { path: '/:filter', element: <ArticleList /> }
          ]
        },
        {
          path: 'subscription',
          children: [
            { path: '/', element: <Navigate to="/subscription/current" replace /> },
            { path: '/:id/subscribe', element: <Subscribe /> },
            { path: '/:page', element: <Subscription /> }
          ]
        },
        {
          path: 'fiche_entreprise',
          children: [
            { path: '/', element: <Navigate to="/fiche_entreprise/ma_fiche_entreprise" replace /> },
            { path: ':page', element: <TouteEntreprise /> }
          ]
        },
        {
          path: 'entreprises',
          children: [
            { path: '/', element: <TouteEntreprise /> },
            { path: ':page/new', element: <TouteEntreprise /> },
            { path: ':page/:agencyId', element: <TouteEntreprise /> },
            { path: ':page', element: <TouteEntreprise /> }
          ]
        },
        {
          path: 'e-commerce',
          children: [
            { path: '/', element: <Navigate to="/e-commerce/shop" replace /> },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> }
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: '/:id/edit', element: <UserEdit /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'account-setting',
          children: [
            { path: '/', element: <Navigate to="/account-setting/change-password" replace /> },
            { path: '/:page', element: <AccountSettings /> }
          ]
        },
        {
          path: 'moderator',
          children: [{ path: '/', element: <Moderator /> }]
        },
        {
          path: 'agency',
          children: [
            { path: '/', element: <Navigate to="/agency/list" replace /> },
            { path: 'list', element: <AgencyList /> },
            { path: 'new', element: <AgencyCreate /> },
            { path: '/:id/edit', element: <AgencyEdit /> }
          ]
        },
        {
          path: 'pays',
          children: [
            { path: '/', element: <Navigate to="/pays/list" replace /> },
            {
              path: '/list',
              element: <CountryList />
            },
            {
              path: 'create',
              element: <CountryCreate />
            },
            {
              path: ':id/edit',
              element: <CountryEdit />
            }
          ]
        },
        {
          path: 'contact',
          children: [
            { path: '/', element: <Navigate to="/contact/list" replace /> },
            { path: 'list', element: <ContactList /> },
            { path: 'new', element: <ContactCreate /> },
            { path: '/:id/edit', element: <ContactEdit /> }
          ]
        },
        {
          path: 'sector',
          children: [
            { path: '/', element: <Navigate to="/sector/list" replace /> },
            { path: 'list', element: <SectorList /> },
            { path: 'new', element: <SectorCreate /> },
            { path: '/:id/edit', element: <SectorEdit /> }
          ]
        },
        {
          path: 'types',
          children: [
            { path: '/', element: <Navigate to="/types/list" replace /> },
            { path: 'list', element: <TypesList /> },
            { path: 'new', element: <TypesCreate /> },
            { path: '/:id/edit', element: <TypesEdit /> }
          ]
        },
        {
          path: 'publicadmis',
          children: [
            { path: '/', element: <Navigate to="/publicadmis/list" replace /> },
            { path: 'list', element: <PublicAdmisList /> },
            { path: 'new', element: <PublicAdmisCreate /> },
            { path: '/:id/edit', element: <PublicAdmisEdit /> }
          ]
        },
        {
          path: 'mobilizabledevice',
          children: [
            { path: '/', element: <Navigate to="/mobilizabledevice/list" replace /> },
            { path: 'list', element: <MobilizableDeviceList /> },
            { path: 'new', element: <MobilizableDeviceCreate /> },
            { path: '/:id/edit', element: <MobilizableDeviceEdit /> }
          ]
        },
        {
          path: 'blog',
          children: [
            { path: '/', element: <Navigate to="/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> }
          ]
        },
        {
          path: 'region',
          children: [
            { path: '/', element: <Navigate to="/region/list" replace /> },
            { path: 'list', element: <RegionList /> },
            { path: 'new', element: <RegionCreate /> },
            { path: '/:id/edit', element: <RegionEdit /> }
          ]
        },
        {
          path: 'city',
          children: [
            { path: '/', element: <Navigate to="/city/list" replace /> },
            { path: 'list', element: <CityList /> },
            { path: 'new', element: <CityCreate /> },
            { path: '/:id/edit', element: <CityEdit /> }
          ]
        },
        {
          path: 'level',
          children: [
            { path: '/', element: <Navigate to="/level/list" replace /> },
            { path: 'list', element: <LevelList /> },
            { path: 'new', element: <LevelCreate /> },
            { path: '/:id/edit', element: <LevelEdit /> }
          ]
        },
        {
          path: 'degree',
          children: [
            { path: '/', element: <Navigate to="/degree/list" replace /> },
            { path: 'list', element: <DegreeList /> },
            { path: 'new', element: <DegreeCreate /> },
            { path: '/:id/edit', element: <DegreeEdit /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '/', element: <Navigate to="/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '/', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'payment', element: <StripePayment /> }
        // { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Docs Routes
    {
      path: 'docs',
      element: <DocsLayout />,
      children: [
        { path: '/', element: <Navigate to="/docs/introduction" replace /> },
        { path: '*', element: <Docs /> }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Logout = Loadable(lazy(() => import('../pages/authentication/Logout')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// Entreprise
const TouteEntreprise = Loadable(lazy(() => import('../pages/dashboard/ma-fiche-entreprise/TouteEntreprise')));
// Setting Account
const AccountSettings = Loadable(lazy(() => import('../pages/dashboard/accountsettings/AccountSettings')));

// Moderator
const Moderator = Loadable(lazy(() => import('../pages/dashboard/moderator/Moderator')));

// user
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/user/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/user/UserCreate')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/user/UserEdit')));

// Article
const ArticleList = Loadable(lazy(() => import('../pages/dashboard/article/ArticleList')));
const ArticleCreate = Loadable(lazy(() => import('../pages/dashboard/article/ArticleCreate')));
const ArticleEdit = Loadable(lazy(() => import('../pages/dashboard/article/ArticleEdit')));

// subscription
const Subscription = Loadable(lazy(() => import('../pages/dashboard/subscription/Subscription')));
const Subscribe = Loadable(lazy(() => import('../pages/dashboard/subscription/Subscribe')));

// Agency
const AgencyList = Loadable(lazy(() => import('../pages/dashboard/agency/AgencyList')));
const AgencyCreate = Loadable(lazy(() => import('../pages/dashboard/agency/AgencyCreate')));
const AgencyEdit = Loadable(lazy(() => import('../pages/dashboard/agency/AgencyEdit')));

// pays
const CountryList = Loadable(lazy(() => import('../pages/dashboard/country/CountryList')));
const CountryCreate = Loadable(lazy(() => import('../pages/dashboard/country/CountryCreate')));
const CountryEdit = Loadable(lazy(() => import('../pages/dashboard/country/CountryEdit')));

// Contact
const ContactList = Loadable(lazy(() => import('../pages/dashboard/contact/ContactList')));
const ContactCreate = Loadable(lazy(() => import('../pages/dashboard/contact/ContactCreate')));
const ContactEdit = Loadable(lazy(() => import('../pages/dashboard/contact/ContactEdit')));

// Sector
const SectorList = Loadable(lazy(() => import('../pages/dashboard/sector/SectorList')));
const SectorCreate = Loadable(lazy(() => import('../pages/dashboard/sector/SectorCreate')));
const SectorEdit = Loadable(lazy(() => import('../pages/dashboard/sector/SectorEdit')));

// Types
const TypesList = Loadable(lazy(() => import('../pages/dashboard/types/TypesList')));
const TypesCreate = Loadable(lazy(() => import('../pages/dashboard/types/TypesCreate')));
const TypesEdit = Loadable(lazy(() => import('../pages/dashboard/types/TypesEdit')));

// PublicAdmis
const PublicAdmisList = Loadable(lazy(() => import('../pages/dashboard/publicadmis/PublicAdmisList')));
const PublicAdmisCreate = Loadable(lazy(() => import('../pages/dashboard/publicadmis/PublicAdmisCreate')));
const PublicAdmisEdit = Loadable(lazy(() => import('../pages/dashboard/publicadmis/PublicAdmisEdit')));

// Mobilizable Device
const MobilizableDeviceList = Loadable(
  lazy(() => import('../pages/dashboard/mobilizabledevice/MobilizableDeviceList'))
);
const MobilizableDeviceCreate = Loadable(
  lazy(() => import('../pages/dashboard/mobilizabledevice/MobilizableDeviceCreate'))
);
const MobilizableDeviceEdit = Loadable(
  lazy(() => import('../pages/dashboard/mobilizabledevice/MobilizableDeviceEdit'))
);

// Region
const RegionList = Loadable(lazy(() => import('../pages/dashboard/region/RegionList')));
const RegionCreate = Loadable(lazy(() => import('../pages/dashboard/region/RegionCreate')));
const RegionEdit = Loadable(lazy(() => import('../pages/dashboard/region/RegionEdit')));

// city
const CityList = Loadable(lazy(() => import('../pages/dashboard/city/cityList')));
const CityCreate = Loadable(lazy(() => import('../pages/dashboard/city/cityCreate')));
const CityEdit = Loadable(lazy(() => import('../pages/dashboard/city/cityEdit')));

// level
const LevelList = Loadable(lazy(() => import('../pages/dashboard/level/LevelList')));
const LevelCreate = Loadable(lazy(() => import('../pages/dashboard/level/LevelCreate')));
const LevelEdit = Loadable(lazy(() => import('../pages/dashboard/level/LevelEdit')));

// degree
const DegreeList = Loadable(lazy(() => import('../pages/dashboard/degree/DegreeList')));
const DegreeCreate = Loadable(lazy(() => import('../pages/dashboard/degree/DegreeCreate')));
const DegreeEdit = Loadable(lazy(() => import('../pages/dashboard/degree/DegreeEdit')));

// payment
const StripePayment = Loadable(lazy(() => import('../pages/dashboard/stripe/Payment')));

const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Docs
const Docs = Loadable(lazy(() => import('../pages/Docs')));
// Main
// const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
// Components
// const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')));
// const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColor')));
// const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
// const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
// const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
// const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
// const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
// const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
// const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
// const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
// const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
// const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
// const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
// const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
// const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
// const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
// const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
// const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
// const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
// const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
// const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
// const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
// const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
// const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
// const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
// const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
// const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
// const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
// const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
// const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
// const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
// const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
// const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
// const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
// const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
// const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
// const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
// const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
// const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
// const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
// const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
// const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
// const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
// const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
// const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
