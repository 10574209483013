import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  publicadmisList: []
};

const slice = createSlice({
  name: 'publicadmis',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE AGENCIES
    getPublicAdmisListSuccess(state, action) {
      state.isLoading = false;
      state.publicadmisList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ---------------------------------------------------------------------

export const getPublicAdmis = createAsyncThunk('publicadmis/getPublicAdmis', async (id, { dispatch }) => {
  const response = await axios.get(`/api/public_admis/${id}`);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const addPublicAdmis = createAsyncThunk('publicadmis/addPublicAdmis', async (publicadmisData, { dispatch }) => {
  const response = await axios.post('/api/public_admis', publicadmisData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updatePublicAdmis = createAsyncThunk(
  'publicadmis/updatePublicAdmis',
  async ({ id, publicadmisData }, { dispatch }) => {
    const response = await axios.put(`/api/public_admis/${id}`, publicadmisData);
    dispatch(slice.actions.endLoading());
    return response.data === undefined ? null : response.data;
  }
);

export const deletePublicAdmis = createAsyncThunk('publicadmis/deletePublicAdmis', async (id, { dispatch }) => {
  const response = await axios.delete(`/api/public_admis/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

export const getAllPublicAdmis = createAsyncThunk('publicadmis/getAllPublicAdmis', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const url = '/api/public_admis';
  const response = await axios.get(url);
  dispatch(slice.actions.getPublicAdmisListSuccess(response.data['hydra:member']));
  return response === undefined ? null : response.data;
});
