import gql from 'graphql-tag';

export const UPSERTAGENCY = gql`
  mutation UPDATEAGENCY($agency: ${process.env.REACT_APP_HASURA_SCHEMA}db_agency_insert_input!) {
    insert_${process.env.REACT_APP_HASURA_SCHEMA}db_agency_one(object: $agency) {
        agency_id
    }
  }
`;

export const UPDATEAGENCY = gql`
  mutation UPDATEAGENCY($agency: ${process.env.REACT_APP_HASURA_SCHEMA}db_agency_set_input, $agency_id: Int!) {
    update_seformer_db_agency_by_pk(pk_columns: { agency_id: $agency_id }, _set: $agency) {
      agency_id
    }
  }
`;

export const UPDATECONTACT = gql`
mutation UPDATEAGENCYCONTACT($contact_id: Int!, $contactInfo: ${process.env.REACT_APP_HASURA_SCHEMA}db_contact_set_input!) {
    update_${process.env.REACT_APP_HASURA_SCHEMA}db_contact_by_pk(pk_columns: {contact_id: $contact_id}, _set: $contactInfo) {
        contact_id
    }
}
`;

export const UPDATEAGENCYADMINFILE = gql`
mutation UPDATEAGENCYADMINFILE($administration_file_id: Int!, $adminFileInfo: ${process.env.REACT_APP_HASURA_SCHEMA}db_administration_file_set_input!) {
    update_${process.env.REACT_APP_HASURA_SCHEMA}db_administration_file_by_pk(pk_columns: {administration_file_id: $administration_file_id}, _set: $adminFileInfo) {
        administration_file_id
    }
}
`;

export const UPSERTPUBLICINFO = gql`
  mutation UPSERTPUBLICINFO($publicInfo: ${process.env.REACT_APP_HASURA_SCHEMA}db_public_info_agency_insert_input!, $_set: seformer_db_address_set_input, $id_agency: Int) {
    insert_${process.env.REACT_APP_HASURA_SCHEMA}db_public_info_agency_one(
        object: $publicInfo, 
        on_conflict: {
            constraint: db_public_info_agency_agency_id_key, 
            update_columns: [main_logo_id, agency_description, total_training_number, training_sucess_rate, people_trained_year, website, sector_id]
        }
    ) {
        public_info_id
    }
    update_seformer_db_address(where: {db_public_info_agencies: {agency_id: {_eq: $id_agency}}}, _set: $_set) {
      affected_rows
    }
  }
`;

export const UPDATE_MEDIA_PUBLIC_AGENCY = gql`
  mutation UPDATEMEDIAPUBLICAGENCY($media_id: [Int], $agency_public_id: Int) {
    remove_media: update_seformer_db_media(
      where: { agency_public_id: { _eq: $agency_public_id } }
      _set: { agency_public_id: null }
    ) {
      affected_rows
    }
    add_media: update_seformer_db_media(
      where: { media_id: { _in: $media_id } }
      _set: { agency_public_id: $agency_public_id }
    ) {
      affected_rows
    }
  }
`;

export const UPDATEADMINISTATIONFILE = gql`
mutation UPDATEADMINISTATIONFILE($administration_file_id: Int!, $administrationfile: ${process.env.REACT_APP_HASURA_SCHEMA}db_administration_file_set_input!) {
    update_seformer_db_administration_file_by_pk(pk_columns: {administration_file_id: $administration_file_id}, _set: $administrationfile) {
        administration_file_id
    }
}
`;

export const INSERTCONTACT = gql`
mutation INSERTCONTACT($contactInfo: ${process.env.REACT_APP_HASURA_SCHEMA}db_contact_insert_input!) {
    insert_${process.env.REACT_APP_HASURA_SCHEMA}db_contact_one(object: $contactInfo) {
        contact_id
    }
  }
`;

export const INSERTAGENCYADMINFILE = gql`
mutation INSERTAGENCYADMINFILE($adminFileInfo: ${process.env.REACT_APP_HASURA_SCHEMA}db_administration_file_insert_input!) {
  insert_${process.env.REACT_APP_HASURA_SCHEMA}db_administration_file_one(object: $adminFileInfo) {
    administration_file_id
  }
}
`;

export const UPLOADFILE = gql`
  mutation ($buffer: String!, $height_full: Int, $name: String!, $type: String, $width_full: Int) {
    UploadFile(buffer: $buffer, height_full: $height_full, name: $name, type: $type, width_full: $width_full) {
      media_id
      filepath
    }
  }
`;

export const UPSERTSUBSCRIPTION = gql`
  mutation UPSERTSUBSCRIPTIONACCOUNT($payment: seformer_db_payement_insert_input!, $desactiveId: Int!) {
    insert_seformer_db_payement_one(object: $payment) {
      payment_id
    }
    update_seformer_db_subscription_account_by_pk(
      pk_columns: { subscrition_account_id: $desactiveId }
      _set: { is_active: false }
    ) {
      subscrition_account_id
    }
  }
`;

export const UPDATESUBSCRIPTION = gql`
  mutation UPDATESUBSCRIPTION($subscriptionAccountId: Int!, $subscriptionAccount: ${process.env.REACT_APP_HASURA_SCHEMA}db_subscription_account_set_input!) {
    update_${process.env.REACT_APP_HASURA_SCHEMA}db_subscription_account_by_pk(pk_columns: {subscrition_account_id: $subscriptionAccountId}, _set: $subscriptionAccount) {
      subscrition_account_id
    }
}
`;

export const UPSERTCONTACT = gql`
  mutation UPSERTCONTACT ($contactInfo: ${process.env.REACT_APP_HASURA_SCHEMA}db_contact_insert_input!, $contact: ${process.env.REACT_APP_HASURA_SCHEMA}db_contact_insert_input!) {
    contactInfo: insert_${process.env.REACT_APP_HASURA_SCHEMA}db_contact_one(
      object: $contactInfo, 
      on_conflict: {
        constraint: db_contact_pkey, 
        update_columns: [email, phone, firstname, lastname, fonction_id]
      }
    ) {
      contact_id
    }
    contact: insert_${process.env.REACT_APP_HASURA_SCHEMA}db_contact_one(
      object: $contact, 
      on_conflict: {
        constraint: db_contact_pkey, 
        update_columns: [email, phone, firstname, lastname, fonction_id]
      }
    ) {
      contact_id
    }
  }
`;

export const UPSERTAGENCYADMINFILE = gql`
  mutation UPSERTAGENCYADMINFILE ($adminFileInfo: ${process.env.REACT_APP_HASURA_SCHEMA}db_administration_file_insert_input!) {
    insert_${process.env.REACT_APP_HASURA_SCHEMA}db_administration_file_one(
      object: $adminFileInfo, 
      on_conflict: {
        constraint: db_administration_file_pkey, 
        update_columns: [facturation_contact_id, social_reason, facturation_address, kbis]
      }
    ) {
      administration_file_id
    }
  }
`;
