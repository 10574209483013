import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { setupInterceptors } from './axios';

export default function AxiosInterceptors() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logout } = useAuth();

    useEffect(() => {
        setupInterceptors({ navigate, dispatch, logout });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    return null;
}