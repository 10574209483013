import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  cities: [],
  city: {},
  regions: []
};

const slice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCities(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    getCity(state, action) {
      state.isLoading = false;
      state.city = action.payload;
    },

    getRegions(state, action) {
      state.isLoading = false;
      state.regions = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const getCity = createAsyncThunk('city/getCity', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.get(`/api/cities/${id}`);
  dispatch(slice.actions.getCity(response.data));
  return response.data === undefined ? null : response.data;
});

// need to edit
export const getCities = createAsyncThunk(
  'city/getCities',
  async ({ order = 'desc', orderBy = 'id', itemsPerPage = '100', name, page = 1 }, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(
      `/api/cities?page=${page}&itemsPerPage=${itemsPerPage}&pagination=true&order%5B${orderBy}%5D=${order}${
        name && name.length ? `&name=${name}` : ''
      }`
    );
    dispatch(slice.actions.getCities(response.data['hydra:member']));
    return response === undefined ? null : response.data;
  }
);

export const addCity = createAsyncThunk('city/addCity', async (city, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.post('/api/cities', city);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateCity = createAsyncThunk('city/updateCity', async ({ id, city }, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.put(`/api/cities/${id}`, city);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteCity = createAsyncThunk('city/deleteCity', async (id, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.delete(`/api/cities/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

// eslint-disable-next-line arrow-body-style
export const deletecityMultiple = createAsyncThunk('city/deleteMultiple', (ids) => {
  return Promise.all(
    ids.map(async (id) => {
      const response = await axios.delete(`/api/cities/${id}}`);
      if (response.data.error) {
        return null;
      }
      return response;
    })
  );
});

export const getRegions = createAsyncThunk('city/getRegions', async (_data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const response = await axios.get('/api/regions');
  dispatch(slice.actions.getRegions(response.data['hydra:member']));
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : response.data;
});
