import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  sectorList: []
};

const slice = createSlice({
  name: 'sector',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE AGENCIES
    getSectorListSuccess(state, action) {
      state.isLoading = false;
      state.sectorList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ---------------------------------------------------------------------

export const getSector = createAsyncThunk('sector/getSector', async (id, { dispatch }) => {
  const response = await axios.get(`/api/sectors/${id}`);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const addSector = createAsyncThunk('sector/addSector', async (sectorData, { dispatch }) => {
  const response = await axios.post('/api/sectors', sectorData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const updateSector = createAsyncThunk('sector/updateSector', async ({ id, sectorData }, { dispatch }) => {
  const response = await axios.put(`/api/sectors/${id}`, sectorData);
  dispatch(slice.actions.endLoading());
  return response.data === undefined ? null : response.data;
});

export const deleteSector = createAsyncThunk('sector/deleteSector', async (id, { dispatch }) => {
  const response = await axios.delete(`/api/sectors/${id}`);
  dispatch(slice.actions.endLoading());
  return response === undefined ? null : id;
});

export const getSectors = createAsyncThunk('sector/getSectors', async (data, { dispatch }) => {
  dispatch(slice.actions.startLoading());
  const url = '/api/sectors';
  const response = await axios.get(url);
  dispatch(slice.actions.getSectorListSuccess(response.data['hydra:member']));
  return response === undefined ? null : response.data;
});
