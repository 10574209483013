// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import DefautlImage from '../assets/images/logo_seformer_sidebar.png';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const srcAvatar = user?.db_agency?.db_public_info_agency?.dbMediaByMainLogoId?.link_full || DefautlImage;
  return (
    <MAvatar
      src={srcAvatar}
      alt={`${user.firstname} ${user.lastname}`}
      color={user.photoURL ? 'default' : createAvatar(user.firstname).color}
      {...other}
      imgProps={{ style: { position: 'absolute', top: 0, left: 0 } }}
    >
      {createAvatar(user.firstname).name}
    </MAvatar>
  );
}
