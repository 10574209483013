import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  mobilizabledeviceList: []
};

const slice = createSlice({
  name: 'mobilizabledevice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE AGENCIES
    getMobilizableDeviceListSuccess(state, action) {
      state.isLoading = false;
      state.mobilizabledeviceList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ---------------------------------------------------------------------

export const getMobilizableDevice = createAsyncThunk(
  'mobilizabledevice/getMobilizableDevice',
  async (id, { dispatch }) => {
    const response = await axios.get(`/api/mobilizable_devices/${id}`);
    dispatch(slice.actions.endLoading());
    return response.data === undefined ? null : response.data;
  }
);

export const addMobilizableDevice = createAsyncThunk(
  'mobilizabledevice/addMobilizableDevice',
  async (mobilizabledeviceData, { dispatch }) => {
    const response = await axios.post('/api/mobilizable_devices', mobilizabledeviceData);
    dispatch(slice.actions.endLoading());
    return response.data === undefined ? null : response.data;
  }
);

export const updateMobilizableDevice = createAsyncThunk(
  'mobilizabledevice/updateMobilizableDevice',
  async ({ id, mobilizabledeviceData }, { dispatch }) => {
    const response = await axios.put(`/api/mobilizable_devices/${id}`, mobilizabledeviceData);
    dispatch(slice.actions.endLoading());
    return response.data === undefined ? null : response.data;
  }
);

export const deleteMobilizableDevice = createAsyncThunk(
  'mobilizabledevice/deleteMobilizableDevice',
  async (id, { dispatch }) => {
    const response = await axios.delete(`/api/mobilizable_devices/${id}`);
    dispatch(slice.actions.endLoading());
    return response === undefined ? null : id;
  }
);

export const getAllMobilizableDevice = createAsyncThunk(
  'mobilizabledevice/getAllMobilizableDevice',
  async (data, { dispatch }) => {
    dispatch(slice.actions.startLoading());
    const url = '/api/mobilizable_devices';
    const response = await axios.get(url);
    dispatch(slice.actions.getMobilizableDeviceListSuccess(response.data['hydra:member']));
    return response === undefined ? null : response.data;
  }
);
