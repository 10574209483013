import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  SidebarLogo: {    
    display:'block',
    textAlign:'center',
    backgroundColor:'transparent',
    '& h6': {
      display:'block',
      color:'#052A0D',
      fontSize:16,
      marginTop:20,
    },
    '& p' :{
      display:'block',
      color:'#052A0D',
      fontWeight:'bold',
      fontSize:16,
    }  
  },
  SidebarAvatar:{
    height:110,
    width:110,
    border:'1px solid #00A998',
    borderRadius:'50%',
    margin:'0 auto',
    display: 'block',
    padding: 12,
    background: '#fff',
    '& img':{
      height:'auto',
      objectFit: 'initial',
    },    
  },
  CTAannonce:{
    backgroundColor:'#00A998',
    color:'#fff',
    fontWeight:'600',
    fontSize:17,
    padding:'15px 30px',
  },
  subliste:{
    position:'relative',
    marginLeft: 40,
    '&::before':{
      content: '""',
      position:'absolute',
      height:'100%',
      width:'1px',
      backgroundColor: theme.palette.primary.main,
      left:20,
    },
    '& a':{
      alignItems:'initial',
      height:'auto',
      paddingBottom:15,
      paddingTop:0,
    },
    "& a:last-child": {
      paddingBottom:0,
    }
  },
  btnReturn:{
    color:theme.palette.grey[600],
    fontSize:13,
    fontWeight:'500',
    display:'flex',
    alignItems:'center',
    marginBottom:'5px',
    '& img':{
      maxWidth:'7px',
      height:'10px',
      width:'100%',
      marginRight:10,
    }
  },
  btnSidebarSeDeconnecter:{
    paddingTop:'10px',
    paddingBottom:'10px',
    fontSize:15,
    fontWeight:500,
    display:'block',
    margin:'0 auto',
    textAlign:'center',
    maxWidth:'170px',
    textTransform:'none'
  },
  TitreEspaceClient:{
    textAlign:'center',
  },
  listemenu:{
 "&:hover":{
  cursor:'pointer',
 }
  },  
  closeSidebar:{
      display:'none',
        [theme.breakpoints.down(1280)]: {
          display:'block',
          position:'absolute',
          right:'0',
          backgroundColor:'#00A998',
          minWidth:'inherit',
          width:'30px',
          height:'30px',
          borderRadius:'50%',
          padding:'0',
          color:'#fff',
        },
  },
  infoAvatar:{
    marginLeft:'0!important',
  },
}));