// ----------------------------------------------------------------------

function path(root, sublink) {
  return (`${root}${sublink}`).replace(/\/\//g, "/");
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  logout: path(ROOTS_AUTH, '/logout'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password/:token')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/:id/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  ficheEntreprise : {
    root: path(ROOTS_DASHBOARD, '/fiche_entreprise'),
    maFicheEntreprise: path(ROOTS_DASHBOARD, '/fiche_entreprise/ma_fiche_entreprise'), 
    coordonneesContactAnnonce: path(ROOTS_DASHBOARD, '/fiche_entreprise/coordonnees_contact_annonce')      
  },
  entreprises : {
    root: path(ROOTS_DASHBOARD, '/entreprises'),
    new: path(ROOTS_DASHBOARD, '/entreprises/new'),
    touteEntreprise: path(ROOTS_DASHBOARD, '/entreprises'), 
    touteSansEntreprise: path(ROOTS_DASHBOARD, '/entreprises/liste_sans_abonnement'), 
    touteAvecEntreprise: path(ROOTS_DASHBOARD, '/entreprises/liste_avec_abonnement'),     
  },
  accountSettings: {
    root: path(ROOTS_DASHBOARD, '/account-setting'),
    changePassword: path(ROOTS_DASHBOARD, '/account-setting/change-password'),
    addNewMember: path(ROOTS_DASHBOARD, '/account-setting/add-new-member'),    
  },

  moderator: {
    root: path(ROOTS_DASHBOARD, '/moderator'),
    customerList: path(ROOTS_DASHBOARD, '/moderator'),
  },

  articles: {
    root: path(ROOTS_DASHBOARD, '/articles'),
    newArticle: path(ROOTS_DASHBOARD, '/articles/new'),
    editByReference: path(ROOTS_DASHBOARD, '/articles/:ref/edit'),
    filterByStatus: path(ROOTS_DASHBOARD, '/articles/:filter')
  },
  subscription: {
    root: path(ROOTS_DASHBOARD, '/subscription'),
    current: path(ROOTS_DASHBOARD, '/subscription/current'),
    change: path(ROOTS_DASHBOARD, '/subscription/change'),
    subscribe: path(ROOTS_DASHBOARD, '/subscription/:id/subscribe'),
    invoices: path(ROOTS_DASHBOARD, '/subscription/invoices')
  },
  agency: {
    root: path(ROOTS_DASHBOARD, '/agency'),
    list: path(ROOTS_DASHBOARD, '/agency/list'),
    newAgency: path(ROOTS_DASHBOARD, '/agency/new'),
    editById: path(ROOTS_DASHBOARD, '/agency/:id/edit')
  },
  country: {
    root: path(ROOTS_DASHBOARD, '/pays'),
    list: path(ROOTS_DASHBOARD, '/pays/list'),
    new: path(ROOTS_DASHBOARD, '/pays/create'),
    edit: path(ROOTS_DASHBOARD, '/pays/:id/edit')
  },
  contact: {
    root: path(ROOTS_DASHBOARD, '/contact'),
    list: path(ROOTS_DASHBOARD, '/contact/list'),
    newContact: path(ROOTS_DASHBOARD, '/contact/new'),
    editById: path(ROOTS_DASHBOARD, '/contact/:id/edit')
  },
  sector: {
    root: path(ROOTS_DASHBOARD, '/sector'),
    list: path(ROOTS_DASHBOARD, '/sector/list'),
    new: path(ROOTS_DASHBOARD, '/sector/new'),
    editById: path(ROOTS_DASHBOARD, '/sector/:id/edit')
  },
  types: {
    root: path(ROOTS_DASHBOARD, '/types'),
    list: path(ROOTS_DASHBOARD, '/types/list'),
    new: path(ROOTS_DASHBOARD, '/types/new'),
    editById: path(ROOTS_DASHBOARD, '/types/:id/edit')
  },
  publicadmis: {
    root: path(ROOTS_DASHBOARD, '/publicadmis'),
    list: path(ROOTS_DASHBOARD, '/publicadmis/list'),
    new: path(ROOTS_DASHBOARD, '/publicadmis/new'),
    editById: path(ROOTS_DASHBOARD, '/publicadmis/:id/edit')
  },
  mobilizabledevice: {
    root: path(ROOTS_DASHBOARD, '/mobilizabledevice'),
    list: path(ROOTS_DASHBOARD, '/mobilizabledevice/list'),
    new: path(ROOTS_DASHBOARD, '/mobilizabledevice/new'),
    editById: path(ROOTS_DASHBOARD, '/mobilizabledevice/:id/edit')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  region: {
    root: path(ROOTS_DASHBOARD, '/region'),
    list: path(ROOTS_DASHBOARD, '/region/list'),
    new: path(ROOTS_DASHBOARD, '/region/new'),
    edit: path(ROOTS_DASHBOARD, '/region/:id/edit')
  },
  city: {
    root: path(ROOTS_DASHBOARD, '/city'),
    list: path(ROOTS_DASHBOARD, '/city/list'),
    new: path(ROOTS_DASHBOARD, '/city/new'),
    edit: path(ROOTS_DASHBOARD, '/city/:id/edit')
  },
  level: {
    root: path(ROOTS_DASHBOARD, '/level'),
    list: path(ROOTS_DASHBOARD, '/level/list'),
    new: path(ROOTS_DASHBOARD, '/level/new'),
    edit: path(ROOTS_DASHBOARD, '/level/:id/edit')
  },
  degree: {
    root: path(ROOTS_DASHBOARD, '/degree'),
    list: path(ROOTS_DASHBOARD, '/degree/list'),
    new: path(ROOTS_DASHBOARD, '/degree/new'),
    edit: path(ROOTS_DASHBOARD, '/degree/:id/edit')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/portfolio-review-is-this-portfolio-too-creative'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  payment: {
    root: path(ROOTS_DASHBOARD, '/payment')
  }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};
