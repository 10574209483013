import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
// components
import { MHidden } from '../../components/@material-extend';
//
import AccountPopover from './AccountPopover';
import { PATH_DASHBOARD } from '../../routes/paths';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  display:'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
   [theme.breakpoints.down(1279)]: {
    display : 'block',
    
   }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar}) {
const { user } = useAuth();
  return (
    <RootStyle>
      <ToolbarStyle >
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />
        {user.isModerator ? (
        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }} alignItems="center">
          <div className="LoadinProfil" style={{ textAlign: 'right' }}>
            <Typography variant="p" color="#052A0D">
              Votre profil est complété à 80%
            </Typography>
            <p>
              <a
                style={{ textDecoration: 'underline', color: '#00A998' }}
                href={PATH_DASHBOARD.ficheEntreprise.maFicheEntreprise}
              >
                Compléter le profil
              </a>
            </p>
          </div>
          <AccountPopover />
        </Stack>
         ) : (
        ''
      )}
      </ToolbarStyle>
    </RootStyle>
  );
}
